import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useRef } from 'react';
import Iconify from 'src/components/iconify/Iconify';
import moment from 'moment';
// @mui
import {
  Card,
  Stack,
  Typography,
  Grid,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import '../muhaberatOutput.css';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import CompleteButton from 'src/components/shared/CompleteButton';
import TextEditor from 'src/components/modals/TextEditor/TextEditor';
import CancelButton from 'src/components/shared/CancelButton';
import SupportTicketsOfUser from './SupportTicketsOfUser';
import Services from 'src/services/Services';
import api from 'src/services/BaseService';
import { useLocation } from 'react-router-dom';
import { tokenService } from 'src/services';
import { useLocalization } from 'src/context/LocalizationContext';
// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SupportTicketsConversation({
  handleCloseDetails,
  ticket,
  getTickets,
  token,
}) {
  const location = useLocation();
  const { language } = useLocalization();

  const [editorState, setEditorState] = useState('');
  const [seeTicketsOfUser, setSeeTicketsOfUser] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(ticket);
  const [showStatusChange, setShowStatusChange] = useState(false);
  const [statusNextType, setStatusNextType] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedSupportUser, setSelectedSupportUser] = useState();
  const [staffList, setStaffList] = useState([]);
  const [disableUserAssignButtons, setDisableUserAssignButtons] =
    useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [showUnauthorizedUserModal, setShowUnauthorizedUserModal] =
    useState(false);
  const [disableAssignButton, setDisableAssignButton] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [storeInformation, setStoreInformation] = useState([]);
  const [userMall, setUserMall] = useState("");
  const containerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const isScrolledToBottom =
          container.scrollHeight - container.scrollTop ===
          container.clientHeight;
        setIsOverflow(!isScrolledToBottom);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      // Initial check
      handleScroll();

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const scrollToBottom = () => {
    const container = containerRef.current;
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    getSupportStaff();
    const currentUserInfo = tokenService.getUser();
    setCurrentUser(currentUserInfo);
    if (currentUserInfo.frontAuth) {
      if (
        currentUserInfo.userID !== selectedTicket.responsibleBy &&
        !currentUserInfo.frontAuth.includes('technical-support-manager') &&
        selectedTicket.createdBy !== currentUserInfo.userID &&
        selectedTicket.responsibleBy
      ) {
        setShowUnauthorizedUserModal(true);
      } else {
        setShowUnauthorizedUserModal(false);
      }
    } else {
      if (
        currentUserInfo.userID !== selectedTicket.responsibleBy &&
        currentUserInfo.type !== 'superadmin' &&
        selectedTicket.createdBy !== currentUserInfo.userID &&
        selectedTicket.responsibleBy
      ) {
        setShowUnauthorizedUserModal(true);
      } else {
        setShowUnauthorizedUserModal(false);
      }
    }
  }, []);

  useEffect(() => {
    setEditorState('');
  }, [selectedTicket]);

  useEffect(() => {
    if (location.pathname.includes('complete')) {
      handleOpenStatusChange('complete');
    }
  }, []);

  const getStores = (data) => {
    let allowedTitles = [];
    if (data.allowedPoints && Array.isArray(data.allowedPoints) && data.allowedPoints.length > 0) {
      const firstResponsible = data.allowedPoints.find(item => item.firstResponsible === true);
      if (firstResponsible) {
        allowedTitles.push(firstResponsible.title);
      } else {
        // Aksi halde, tüm title'ları al
        allowedTitles = data.allowedPoints.map(item => item.title);
      }
    }
  
    let branchTitles = [];
    if (
      data.authLocations &&
      data.authLocations.branch &&
      Array.isArray(data.authLocations.branch) &&
      data.authLocations.branch.length > 0
    ) {
      branchTitles = data.authLocations.branch.map(item => item.title);
    }
  
    const allStores = [...allowedTitles, ...branchTitles];
    const uniqueStores = Array.from(new Set(allStores));
    console.log(uniqueStores,"unique");
    return uniqueStores;
  };

  function getMalls(selectedMallID) {
    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        if (response.data) {
          setUserMall(response.data.result.find(x => x.id === selectedMallID).title);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    Services.GetUser(token, selectedTicket.createdBy)
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.result, "-");
          const stores = getStores(response.data.result);
          console.log(stores);
          setStoreInformation(stores)
          getMalls(response.data.result.mallID)
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCompleteStaffAssign = () => {
    assignStaffForTicket();
    setDisableUserAssignButtons(true);
  };

  const handeCloseUserModal = () => {
    setUserModalOpen(false);
    setSelectedSupportUser();
  };

  const handeOpenUserModal = () => {
    if (selectedTicket.responsibleBy) {
      setDisableAssignButton(true);
      getUserForTicket();
    } else {
      setUserModalOpen(true);
    }
  };

  const handleUserChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSupportUser(value);
  };

  const handleCloseStatusChange = () => {
    setShowStatusChange(false);
  };

  const handleOpenStatusChange = (type) => {
    if (type === 'cancel') {
      setStatusNextType('cancel');
      setDialogMessage('Talebi iptal etmek istediğinize emin misiniz?');
    } else if (type === 'complete') {
      setStatusNextType('complete');
      setDialogMessage('Talebi tamamlamak istediğinize emin misiniz?');
    } else if (type === 'setInProgress') {
      setStatusNextType('setInProgress');
      setDialogMessage('Talebi işleme almak istediğinize emin misiniz?');
    }
    setShowStatusChange(true);
  };

  const handleConfirmStatusChange = () => {
    if (statusNextType === 'cancel') {
      cancelTicket();
      setShowStatusChange(false);
    } else if (statusNextType === 'complete') {
      completeTicket();
      setShowStatusChange(false);
    } else if (statusNextType === 'setInProgress') {
      setTicketInProgress();
      setShowStatusChange(false);
    }
  };

  const onEditorStateChange = (text) => {
    setEditorState(text);
  };

  const getUserForTicket = () => {
    Services.GetUserForTickets(token, selectedTicket.responsibleBy)
      .then((response) => {
        if (response.data.status) {
          setSelectedSupportUser(response.data.result.id);
          setUserModalOpen(true);
          setDisableAssignButton(false);
        } else {
          setDisableAssignButton(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTicket = () => {
    api
      .post('flow/get-ticket', {
        id: selectedTicket.id,
      })
      .then((response) => {
        if (response.data.status) {
          setSelectedTicket(response.data.result);
          getTickets();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const assignStaffForTicket = () => {
    api
      .post('flow/assign-ticket-to-user', {
        id: selectedTicket.id,
        userID: selectedSupportUser,
      })
      .then((response) => {
        if (response.data.status) {
          setUserModalOpen(false);
          setDisableUserAssignButtons(true);
          handleCloseDetails();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSupportStaff = () => {
    api
      .post('flow/get-users-by-front-auth', {
        value: ['technical-support-staff', 'technical-support-manager'],
      })
      .then((response) => {
        if (response.data.status) {
          setStaffList(response.data.result);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancelTicket = () => {
    api
      .post('flow/ticket-cancel', {
        id: selectedTicket.id,
      })
      .then((response) => {
        if (response.data.status) {
          getTicket();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setTicketInProgress = () => {
    api
      .post('flow/ticket-set-in-progress', {
        id: selectedTicket.id,
      })
      .then((response) => {
        if (response.data.status) {
          getTicket();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const completeTicket = () => {
    api
      .post('flow/ticket-complete', {
        id: selectedTicket.id,
      })
      .then((response) => {
        if (response.data.status) {
          getTicket();
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case '1':
        return '#079455'; // Yeşil
      case '2':
        return '#FF4C51'; // Kırmızı
      case '3':
        return '#FFB400'; // Sarı
      case '4':
        return '#9155FD'; // Mor
      case '5':
        return '#FE6B8B'; // Fuşya
      default:
        return ''; // Varsayılan durum
    }
  };


  const statusTypes = [
    { value: '1', label: language.commons.statusTypes.finish }, //yeşil
    { value: '2', label: language.commons.statusTypes.cancel }, //kırmızı
    { value: '3', label: language.commons.statusTypes.pending }, //sarı
    { value: '4', label: language.commons.statusTypes.inProgress }, //mor
    { value: '5', label: language.commons.statusTypes.awaitingResponse }, //fuşya,lila gradient
  ];

  const getStatusFromValue = (value) => {
    const statusType = statusTypes.find((type) => type.value === value);
    return statusType ? statusType.label : null;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${selectedTicket.email}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${selectedTicket.phone}`;
  };

  return (
    <>
      <Helmet>
        <title> {language.support_ticket_conversation.titles.site_title}</title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.support_ticket_conversation.titles.title}
          </Typography>

        </Stack>
      </Stack>

      <Grid
        container
        item
        xs={12}
        spacing={4}
        sx={{ justifyContent: 'center', display: 'flex' }}
      >
        {/* left card */}
        <Grid item xs={10} sm={6} md={3}>
          <Card
            sx={{
              height: '689px',
              borderRadius: '5px',
              alignItems: 'center',
              display: 'flex',
              padding: '30px',
              flexDirection: 'column',
            }}
          >
            <Card
              sx={{
                width: '120px',
                height: '120px',
                backgroundColor: '#172955',
                borderRadius: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                padding: '10px',
              }}
            >
              <img src="/assets/logo.png" alt="logo" />
            </Card>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                color: '#3A3541DE',
                fontSize: '20px',
                letterSpacing: 0.15,
                lineHeight: '32px',
                marginTop: '16px',
              }}
            >
              {selectedTicket.name} {selectedTicket.lastname}
            </Typography>
            <Card
              sx={{
                width: '121px',
                height: '32px',
                backgroundColor: '#D9D6FE',
                borderRadius: '5px',
                marginTop: '16px',
                padding: '4px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  color: '#000',
                  fontSize: '13px',
                  letterSpacing: 0.16,
                  lineHeight: '18px',
                }}
              >
                {selectedTicket.createdByType
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + selectedTicket.createdByType.slice(1)}
              </Typography>
            </Card>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                color: '#3A3541DE',
                fontSize: '20px',
                letterSpacing: 0.15,
                lineHeight: '32px',
                marginTop: '32px',
                alignSelf: 'flex-start',
                marginBottom: '16px',
              }}
            >
              {language.commons.labels.user_detail}
            </Typography>
            <HorizontalDivider />
            <Stack
              sx={{
                flexDirection: 'column',
                display: 'flex',
                width: '100%',
                marginTop: '16px',
              }}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.labels.username}:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                    color: '#3A3541AD',
                    marginLeft: '5px',
                  }}
                >
                  {selectedTicket.username}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  width: '100%',
                  marginTop: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.labels.e_mail}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                    color: '#3A3541AD',
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleEmailClick}
                >
                  {selectedTicket.email}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  width: '100%',
                  marginTop: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.labels.phone}:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                    color: '#3A3541AD',
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handlePhoneClick}
                >
                  {selectedTicket.phone ? selectedTicket.phone : '-'}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  width: '100%',
                  marginTop: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.labels.frontAuth}:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                    color: '#3A3541AD',
                    marginLeft: '5px',
                  }}
                >
                  {selectedTicket.createdByType
                    .toLowerCase()
                    .charAt(0)
                    .toUpperCase() + selectedTicket.createdByType.slice(1)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  width: '100%',
                  marginTop: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.column.store}:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                    color: '#3A3541AD',
                    marginLeft: '5px',
                  }}
                >
                  {storeInformation.length > 0 ? storeInformation.join(", ") : "-"}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  width: '100%',
                  marginTop: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 500,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.column.mall}:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '22px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    fontFamily: 'Inter',
                    color: '#3A3541AD',
                    marginLeft: '5px',
                  }}
                >
                  {userMall ? userMall : "-"}
                </Typography>
              </Stack>
            </Stack>
            {!seeTicketsOfUser && (
              <Stack
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  marginTop: '53px',
                  alignSelf: 'center',
                }}
              >
                <CompleteButton
                  onClick={() => setSeeTicketsOfUser(true)}
                  label={language.support_ticket_conversation.titles.user_req}
                  sx={{ height: '38px' }}
                />
              </Stack>
            )}
          </Card>
        </Grid>
        {/* right cards */}
        {seeTicketsOfUser ? (
          <SupportTicketsOfUser
            handleCloseDetails={handleCloseDetails}
            createdBy={selectedTicket.createdBy}
            setSeeTicketsOfUser={setSeeTicketsOfUser}
            setSelectedTicket={setSelectedTicket}
          />
        ) : (
          <Grid item xs={10} sm={6} md={7}>
            <Card
              sx={{
                height: '1000px',
                borderRadius: '5px',
                padding: '2%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>

                <div
                  style={{
                    width: '119px',
                    marginRight: '2.5%',
                    height: '40px',
                    backgroundColor: getStatusColor(selectedTicket.status),
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignSelf: 'flex-end',
                    display: 'flex',
                    borderRadius: 5,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '12px',
                      color: '#fff',
                      fontFamily: 'Inter',
                    }}
                  >
                    {getStatusFromValue(
                      selectedTicket.status
                    ).toLocaleUpperCase()}
                  </Typography>
                </div>
                <Card sx={{ height: '40px', width: '40px', borderRadius: '5px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: '2.5%', backgroundColor: '#172955' }}>
                  <Button onClick={() => handleCloseDetails()} sx={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Iconify sx={{ color: '#fff', height: '16px' }} icon="material-symbols:arrow-back-ios-new" />
                  </Button>
                </Card>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '95%',
                  marginBottom: '2%',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '18px',
                    color: '#858585',
                    fontFamily: 'Inter',
                    alignSelf: 'flex-start',
                  }}
                >
                  {language.commons.labels.subject}: {selectedTicket.title}
                </Typography>
              </div>
              <Card
                sx={{
                  borderRadius: '5px',
                  backgroundColor: '#FAFAFA',
                  height: '93%',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  display: 'flex',
                  width: '95%',
                  flexDirection: 'column',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  paddingBottom: '25px',
                }}
              >
                <div
                  ref={containerRef}
                  style={{
                    overflowY: 'auto',
                    backgroundColor: '#FAFAFA',
                    height: '100%',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    marginTop: '32px',
                    paddingLeft: '2.5%',
                    paddingRight: '2.5%',
                  }}
                >
                  <div
                    style={{
                      height: '11%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginBottom: '20px',
                    }}
                  >
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                      <div
                        style={{
                          width: '38px',
                          height: '38px',
                          backgroundColor: 'gray',
                          borderRadius: 50,
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography
                          sx={{
                            color: 'white',
                            fontWeight: 400,
                            fontSize: '16px',
                            fontFamily: 'Inter',
                          }}
                        >
                          {selectedTicket.name.slice(0, 1).toUpperCase()}
                        </Typography>
                      </div>
                      <div
                        style={{ flexDirection: 'column', marginLeft: '10px' }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            color: '#475467',
                            fontFamily: 'Inter',
                          }}
                        >
                          {selectedTicket.name} {selectedTicket.lastname}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#3A354161',
                            fontFamily: 'Inter',
                          }}
                        >
                          {selectedTicket.email}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: '#98A2B3',
                          fontFamily: 'Inter',
                          marginRight: '13px',
                        }}
                      >
                        {moment
                          .unix(selectedTicket.createdDateTime)
                          .format('DD MMMM YYYY hh:mm A')}
                      </Typography>
                      {selectedTicket.files && (
                        <Iconify
                          icon="mdi:attachment"
                          sx={{ color: '#98A2B3' }}
                        />
                      )}
                    </div>
                  </div>

                  <HorizontalDivider />
                  <div
                    style={{
                      width: '100%',
                      marginBottom: '42px',
                      marginTop: '32px',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedTicket.description }}
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    />
                    {Array.isArray(selectedTicket.files) && (
                      <Grid
                        container
                        xs={12}
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'flex-start',
                        }}
                      >
                        {selectedTicket.files?.map((file, index) => (
                          <Grid item xs={2} key={index}>
                            <Button
                              sx={{ backgroundColor: 'white' }}
                              startIcon={<Iconify icon="mdi:attachment" />}
                              variant="outlined"
                              onClick={() => window.open(file, '_blank')}
                            >
                              {language.commons.labels.additional} {index + 1}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </div>
                  <HorizontalDivider />
                  {selectedTicket.conversations &&
                    Array.isArray(selectedTicket.conversations) &&
                    selectedTicket.conversations.map((item, index) => (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '25px',
                          marginBottom: '25px',
                        }}
                      >
                        <div
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                width: '38px',
                                height: '38px',
                                backgroundColor: '#172955',
                                borderRadius: 50,
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: 'white',
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item.user.name.slice(0, 1).toUpperCase()}
                              </Typography>
                            </div>
                            <div
                              style={{
                                flexDirection: 'column',
                                marginLeft: '10px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  color: '#475467',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item.user.name} {item.user.lastname}
                              </Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#98A2B3',
                                fontFamily: 'Inter',
                                marginRight: '13px',
                              }}
                            >
                              {moment
                                .unix(item.createdDateTime)
                                .format('DD MMMM YYYY hh:mm A')}
                            </Typography>
                            {item.files && (
                              <Iconify
                                icon="mdi:attachment"
                                sx={{ color: '#98A2B3' }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.response }}
                          style={{
                            whiteSpace: 'pre-wrap',
                            marginTop: '52px',
                            marginBottom: '42px',
                          }}
                        />

                        {Array.isArray(item.files) && (
                          <Grid
                            container
                            xs={12}
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'flex-start',
                              marginBottom: '42px',
                            }}
                          >
                            {item.files?.map((file, index) => (
                              <Grid item xs={2} key={index}>
                                <Button
                                  sx={{ backgroundColor: 'white' }}
                                  startIcon={<Iconify icon="mdi:attachment" />}
                                  variant="outlined"
                                  onClick={() => window.open(file, '_blank')}
                                >
                                  {language.commons.labels.additional} {index + 1}
                                </Button>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                        <HorizontalDivider />
                      </div>
                    ))}

                  {isOverflow && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        marginBottom: 15,
                        textAlign: 'center',
                        bottom:
                          selectedTicket.status !== '1' &&
                            selectedTicket.status !== '2' &&
                            selectedTicket.status !== '3'
                            ? '23%'
                            : 0,
                      }}
                    >
                      <Button
                        onClick={scrollToBottom}
                        sx={{
                          textTransform: 'none',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                        variant="contained"
                        color="primary"
                      >
                        {language.commons.labels.go_last_message}
                      </Button>
                    </div>
                  )}
                </div>

                {selectedTicket.status !== '1' &&
                  selectedTicket.status !== '2' &&
                  selectedTicket.status !== '3' && (
                    <TextEditor
                      onEditorStateChange={onEditorStateChange}
                      editorState={editorState}
                      id={selectedTicket.id}
                      getTicket={() => getTicket()}
                      getTickets={() => getTickets}
                    />
                  )}
              </Card>
              <div
                style={{
                  width: '95%',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  display: 'flex',
                  marginTop: '2%',
                  gap: '15px',
                }}
              >
                {currentUser?.type !== 'superadmin' ? (
                  <>
                    {currentUser?.frontAuth !== false && (
                      <>
                        {selectedTicket.status !== '2' &&
                          selectedTicket.status !== '1' &&
                          (currentUser?.frontAuth?.includes(
                            'technical-support-staff'
                          ) ||
                            currentUser?.frontAuth?.includes(
                              'technical-support-manager'
                            )) && (
                            <CompleteButton
                              onClick={() => handeOpenUserModal()}
                              disabled={disableAssignButton}
                              label={'Atama Yap'}
                              sx={{
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                height: '40px',
                              }}
                            />
                          )}
                      </>
                    )}

                    {selectedTicket.status !== '2' &&
                      selectedTicket.status !== '1' && (
                        <CancelButton
                          onClick={() => handleOpenStatusChange('cancel')}
                          label={language.commons.cancel}
                          sx={{
                            backgroundColor: 'white',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            height: '40px',
                          }}
                        />
                      )}

                    {currentUser?.frontAuth !== false && (
                      <>
                        {selectedTicket.status === '3' &&
                          (currentUser?.frontAuth?.includes(
                            'technical-support-staff'
                          ) ||
                            currentUser?.frontAuth?.includes(
                              'technical-support-manager'
                            )) && (
                            <CompleteButton
                              onClick={() =>
                                handleOpenStatusChange('setInProgress')
                              }
                              label={language.support_ticket_conversation.titles.take_operation}
                              sx={{
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                height: '40px',
                              }}
                            />
                          )}
                      </>
                    )}

                    {(selectedTicket.status === '4' ||
                      selectedTicket.status === '5') && (
                        <CompleteButton
                          onClick={() => handleOpenStatusChange('complete')}
                          label={language.commons.statusTypes.finish}
                          sx={{
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            height: '40px',
                          }}
                        />
                      )}
                  </>
                ) : (
                  <>
                    {selectedTicket.status !== '2' &&
                      selectedTicket.status !== '1' && (
                        <CompleteButton
                          onClick={() => handeOpenUserModal()}
                          disabled={disableAssignButton}
                          label={language.support_ticket_conversation.titles.assignment}
                          sx={{
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            height: '40px',
                          }}
                        />
                      )}

                    {selectedTicket.status !== '2' &&
                      selectedTicket.status !== '1' && (
                        <CancelButton
                          onClick={() => handleOpenStatusChange('cancel')}
                          label={language.commons.cancel}
                          sx={{
                            backgroundColor: 'white',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            height: '40px',
                          }}
                        />
                      )}

                    {selectedTicket.status === '3' && (
                      <CompleteButton
                        onClick={() => handleOpenStatusChange('setInProgress')}
                        label={language.support_ticket_conversation.titles.take_operation}
                        sx={{
                          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                          height: '40px',
                        }}
                      />
                    )}

                    {(selectedTicket.status === '4' ||
                      selectedTicket.status === '5') && (
                        <CompleteButton
                          onClick={() => handleOpenStatusChange('complete')}
                          label={language.commons.statusTypes.finish}
                          sx={{
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            height: '40px',
                          }}
                        />
                      )}
                  </>
                )}
              </div>
            </Card>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={showUnauthorizedUserModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {language.support_ticket_conversation.titles.sup_req_access}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {language.support_ticket_conversation.titles.no_access}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>{language.commons.okay}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showStatusChange}
        onClose={handleCloseStatusChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {language.support_ticket_conversation.titles.req_change}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusChange}>{language.commons.no}</Button>
          <Button onClick={handleConfirmStatusChange}>{language.commons.yes}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={userModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '500px' }}>
          <Grid item xs={12} container alignItems="center" spacing={2}>
            <Grid xs={12} item>
              <Typography variant="h6"> {language.support_ticket_conversation.titles.change_assignment}</Typography>
            </Grid>

            <Grid xs={12} item>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="user">{language.support_ticket_conversation.titles.tech_support}</InputLabel>
                <Select
                  disabled={disableUserAssignButtons}
                  labelId="user"
                  value={selectedSupportUser}
                  onChange={handleUserChange}
                  input={<OutlinedInput label={language.support_ticket_conversation.titles.tech_support} />}
                  MenuProps={MenuProps}
                >
                  {staffList?.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name} {user.lastname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disableUserAssignButtons}
            onClick={handeCloseUserModal}
          >
            {language.commons.no}
          </Button>
          <Button
            disabled={!selectedSupportUser || disableUserAssignButtons}
            onClick={handleCompleteStaffAssign}
          >
            {language.commons.yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
