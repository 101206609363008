import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import 'moment/locale/tr';
// @mui
import { Grid, Stack } from '@mui/material';
// sections
import {
  AppCurrentVisits,
  AppWidgetSummary,
  AppWebsiteVisits,
  AppConversionRates,
} from '../sections/@dashboard/app';
import AppAllDatas from 'src/sections/@dashboard/app/AppAllDatas';
import Services from 'src/services/Services';
import AppLineDatas from 'src/sections/@dashboard/app/AppLineDatas';
import { useLocalization } from '../context/LocalizationContext';
import { replacePlaceholders } from 'src/utils/textUtils';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { language } = useLocalization();

  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  // weekly and monthly
  const [weeklyTransaction, setWeeklyTransaction] = useState(0);
  const [weeklyPackage, setWeeklyPackage] = useState(0);
  const [monthlyTransaction, setMonthlyTransaction] = useState(0);
  const [monthlyPackage, setMonthlyPackage] = useState(0);

  // shipments by status
  const [postsReadyToCarry, setPostsReadyToCarry] = useState(0);
  const [transactionsOnCarrying, setTransactionsOnCarrying] = useState(0);
  const [transactionsCompleted, setTransactionsCompleted] = useState(0);

  // vehicles (dinamik kullanım için)
  const [vehicles, setVehicles] = useState({});
  // Cargo vehicles için dinamik state
  const [cargoVehiclesData, setCargoVehiclesData] = useState([]);

  // accept goods and post offices
  const [acceptGoodsCount, setAcceptGoodsCount] = useState(0);
  const [postOfficeCount, setPostOfficeCount] = useState(0);

  // handshakes
  const [manuelHandshakes, setManuelHandshakes] = useState(0);
  const [normalHandshakes, setNormalHandshakes] = useState(0);
  // waybills
  const [weeklyTotalWaybills, setWeeklyTotalWaybills] = useState([]);
  const [weeklyCompletedWaybills, setWeeklyCompletedWaybills] = useState([]);
  const [monthlyTotalWaybills, setMonthlyTotalWaybills] = useState([]);
  const [monthlyCompletedWaybills, setMonthlyCompletedWaybills] = useState([]);

  const [firmsSeries, setFirmsSeries] = useState();
  const [firmsLabels, setFirmsLabels] = useState();
  // const [hideFirms, setHideFirms] = useState(false);

  function transformFirmsData(firmsData) {
    if (firmsData === 0) {
      // setHideFirms(true);
      return null;
    }

    const monthKeys = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];
    const monthLabels = [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık',
    ];

    // Tüm aylardaki her firmanın toplamını hesaplamak için
    const totalByFirm = {};

    // Her ay için, "Diğer" anahtarını çıkararak temizlenmiş veriyi oluşturuyoruz.
    const cleanedData = {};
    monthKeys.forEach((monthKey) => {
      // O ayın verisini alıyoruz; veri yoksa boş obje
      const monthData = firmsData[monthKey] || {};

      // "Diğer" değerini çıkarıp saklıyoruz
      const removedOtherValue = monthData['Diğer'] || 0;

      // "Diğer" dışındaki firmaları kopyalıyoruz
      const newMonthData = {};
      Object.entries(monthData).forEach(([firm, value]) => {
        if (firm === 'Diğer') return;
        newMonthData[firm] = value;
        totalByFirm[firm] = (totalByFirm[firm] || 0) + value;
      });

      // Sonrasında diğer firma toplamları için _removedOther alanını ekliyoruz
      newMonthData._removedOther = removedOtherValue;
      cleanedData[monthKey] = newMonthData;
    });

    // Tüm aylardaki toplam değerlerine göre en yüksek değere sahip ilk 5 firmayı seçiyoruz.
    const topFirms = Object.entries(totalByFirm)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([firm]) => firm);

    // Grafik için seri verilerini oluşturuyoruz.
    // Burada her seri, seçilen top 5 firmaya ait aylık verileri barındıracak.
    // Ek olarak 6. seri "Diğer" kategorisini oluşturacak.
    const series = {};
    topFirms.forEach((firm) => {
      series[firm] = [];
    });
    series['Diğer'] = [];

    // Her ay için verileri dizilere ekliyoruz.
    monthKeys.forEach((monthKey) => {
      const monthData = cleanedData[monthKey] || {};

      // Seçilen top 5 firma için, eğer o ayda veri yoksa 0 ekle
      topFirms.forEach((firm) => {
        series[firm].push(monthData[firm] || 0);
      });

      // Diğer firmaların (top 5 dışı) değerlerini toplayıp,
      // başlangıçta çıkarılan "Diğer" değerini de ekliyoruz
      let othersSum = monthData._removedOther || 0;
      Object.entries(monthData).forEach(([firm, value]) => {
        if (firm === '_removedOther') return;
        if (!topFirms.includes(firm)) {
          othersSum += value;
        }
      });
      series['Diğer'].push(othersSum);
    });

    // Grafik bileşeninizin beklediği formatta diziye dönüştürüyoruz.
    const seriesData = Object.entries(series).map(([name, data]) => ({
      name,
      data,
    }));

    // Örnek: Eğer grafik bileşeniniz hem veri hem de x eksenindeki ay etiketlerini bekliyorsa,
    // dönüş olarak her iki değeri içeren bir obje döndürebilirsiniz.
    return {
      seriesData, // Her seri: top 5 firma ve "Diğer" kategorisi
      labels: monthLabels, // Türkçe ay isimleri
    };
  }

  const fetchCargoStatistics = async (type) => {
    const token = parsedUser.token;

    if (parsedUser.accountCategory === 'store') {
      const branchID = localStorage.getItem('branchID');
      try {
        const res = await Services.GetCargoStatisticsByBranch(
          token,
          branchID,
          type
        );
        if (res.data.status) {
          return res.data.result;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    } else {
      const mallID = localStorage.getItem('mallID');
      try {
        const res = await Services.GetCargoStatisticsByMall(
          token,
          mallID,
          type
        );
        if (res.data.status) {
          return res.data.result;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    }
  };

  const fetchStatistics = async (type, date) => {
    const token = parsedUser.token;

    if (parsedUser.accountCategory === 'store') {
      const branchID = localStorage.getItem('branchID');
      try {
        const res = await Services.GetStatisticsByBranch(
          token,
          branchID,
          type,
          date
        );
        if (res.data.status) {
          return res.data.result;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    } else {
      const mallID = localStorage.getItem('mallID');
      try {
        const res = await Services.GetStatisticsByMall(
          token,
          mallID,
          type,
          date
        );
        if (res.data.status) {
          return res.data.result;
        } else {
          return 0;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    }
  };

  const formatWeeklyData = (data) => {
    const weekDaysList = Array.from({ length: 7 }, (_, i) =>
      moment().startOf('week').add(i, 'days').format('YYYY-MM-DD')
    );
    const weeklyDataList = Array(7).fill(0);

    for (const [date, value] of Object.entries(data)) {
      const index = weekDaysList.indexOf(date);
      if (index !== -1) {
        weeklyDataList[index] = value;
      }
    }

    return weeklyDataList;
  };

  const formatMonthlyData = (data) => {
    const monthDaysList = Array.from(
      { length: moment().daysInMonth() },
      (_, i) => moment().startOf('month').add(i, 'days').format('YYYY-MM-DD')
    );
    const monthlyDataList = Array(monthDaysList.length).fill(0);

    for (const [date, value] of Object.entries(data)) {
      const index = monthDaysList.indexOf(date);
      if (index !== -1) {
        monthlyDataList[index] = value;
      }
    }

    return monthlyDataList;
  };

  const fetchGraphStatistics = async (
    type,
    date,
    onlyCompleted,
    weeklyOrMonthly
  ) => {
    const token = parsedUser.token;

    if (parsedUser.accountCategory === 'store') {
      const branchID = localStorage.getItem('branchID');
      try {
        const res = await Services.GetGraphStatisticsByBranch(
          token,
          branchID,
          type,
          date,
          onlyCompleted
        );
        if (res.data.status) {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData(res.data.result)
            : formatMonthlyData(res.data.result);
        } else {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData({})
            : formatMonthlyData({});
        }
      } catch (error) {
        console.error(error);
        return weeklyOrMonthly === 'weekly'
          ? formatWeeklyData({})
          : formatMonthlyData({});
      }
    } else {
      const mallID = localStorage.getItem('mallID');
      try {
        const res = await Services.GetGraphStatisticsByMall(
          token,
          mallID,
          type,
          date,
          onlyCompleted
        );
        if (res.data.status) {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData(res.data.result)
            : formatMonthlyData(res.data.result);
        } else {
          return weeklyOrMonthly === 'weekly'
            ? formatWeeklyData({})
            : formatMonthlyData({});
        }
      } catch (error) {
        console.error(error);
        return weeklyOrMonthly === 'weekly'
          ? formatWeeklyData({})
          : formatMonthlyData({});
      }
    }
  };

  useEffect(() => {
    //weekly and monthly
    const fetchWeeklyTransactions = async () => {
      const _weeklyTransactions = await fetchStatistics(
        'posts-completed',
        'weekly'
      );
      setWeeklyTransaction(_weeklyTransactions.totalPosts);
    };

    const fetchWeeklyPackages = async () => {
      const _weeklyTransactions = await fetchStatistics(
        'posts-completed',
        'weekly'
      );
      setWeeklyPackage(_weeklyTransactions.totalPackageCount);
    };

    const fetchMonthlyTransactions = async () => {
      const _monthlyTransactions = await fetchStatistics(
        'posts-completed',
        'monthly'
      );
      setMonthlyTransaction(_monthlyTransactions.totalPosts);
    };

    const fetchMonthlyPackages = async () => {
      const _monthlyTransactions = await fetchStatistics(
        'posts-completed',
        'monthly'
      );
      setMonthlyPackage(_monthlyTransactions.totalPackageCount);
    };

    // Shipments by status
    const fetchReadyToCarry = async () => {
      const _readyToCarryPosts = await fetchStatistics(
        'posts-ready-to-carry',
        'yearly'
      );
      setPostsReadyToCarry(_readyToCarryPosts);
    };

    const fetchOnPorter = async () => {
      const _onPorter = await fetchStatistics(
        'transactions-on-carrying',
        'yearly'
      );
      setTransactionsOnCarrying(_onPorter);
    };

    const fetchCompleteds = async () => {
      const _completeds = await fetchStatistics(
        'transactions-completed',
        'yearly'
      );
      setTransactionsCompleted(_completeds);
    };

    // Pie charts
    // Vehicles (dinamik)
    const fetchVehicles = async () => {
      const _vehicles = await fetchStatistics('handshakes-vehicles', 'yearly');
      setVehicles(_vehicles !== 0 ? _vehicles : {});
    };

    // Cargo Vehicles (dinamik sıralama ve ilk 5 + Diğer)
    const fetchCargoVehicles = async () => {
      const _cargoVehicles = await fetchStatistics(
        'posts-shipment-firms',
        'yearly'
      );
      if (_cargoVehicles !== 0) {
        const vehiclesArray = Object.entries(_cargoVehicles).map(
          ([label, value]) => ({ label, value })
        );
        // Değerine göre azalan sıralama
        vehiclesArray.sort((a, b) => b.value - a.value);
        const topFive = vehiclesArray.slice(0, 5);
        // Kalan tüm değerlerin toplamını hesapla
        const othersValue = vehiclesArray
          .slice(5)
          .reduce((acc, curr) => acc + curr.value, 0);
        if (othersValue > 0) {
          topFive.push({ label: 'Diğer', value: othersValue });
        }
        setCargoVehiclesData(topFive);
      } else {
        setCargoVehiclesData([]);
      }
    };

    // Posts types
    const fetchPostsTypes = async () => {
      const _postsTypes = await fetchStatistics('posts-types', 'yearly');
      setPostOfficeCount(_postsTypes?.postOffice ? _postsTypes?.postOffice : 0);
      setAcceptGoodsCount(
        _postsTypes?.corporationalAcceptGoods
          ? _postsTypes?.corporationalAcceptGoods
          : 0
      );
    };

    // Handshakes
    const fetchHandshakes = async () => {
      const _handshakes = await fetchStatistics('handshakes-types', 'yearly');
      setNormalHandshakes(_handshakes?.qr ? _handshakes?.qr : 0);
      setManuelHandshakes(_handshakes?.manuel ? _handshakes?.manuel : 0);
    };

    // shipment firms current year
    const fetchFirms = async () => {
      const _firms = await fetchCargoStatistics(
        'get-all-shipment-firms-analytics-in-curret-year'
      );
      const transformedData = transformFirmsData(_firms);
      if (transformedData) {
        setFirmsSeries(transformedData.seriesData);
        setFirmsLabels(transformedData.labels);
      }
    };

    // Waybills
    const fetchWaybills = async (weeklyOrMonthly) => {
      const today = new Date();
      const currentDay = today.getDay();
      if (weeklyOrMonthly === 'weekly') {
        const startOfCurrentWeek = new Date(today);
        startOfCurrentWeek.setDate(
          today.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
        );
        const endOfCurrentWeek = new Date(today);
        endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

        const formattedStartDate = startOfCurrentWeek
          .toISOString()
          .split('T')[0];
        const formattedEndDate = endOfCurrentWeek.toISOString().split('T')[0];
        const _weeklyTotalWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          { startDate: formattedStartDate, endDate: formattedEndDate },
          false,
          'weekly'
        );
        const _weeklyCompletedWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          { startDate: formattedStartDate, endDate: formattedEndDate },
          true,
          'weekly'
        );
        setWeeklyTotalWaybills(_weeklyTotalWaybills);
        setWeeklyCompletedWaybills(_weeklyCompletedWaybills);
      } else {
        const startOfCurrentMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const endOfCurrentMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );

        const formattedStartDate = startOfCurrentMonth
          .toISOString()
          .split('T')[0];
        const formattedEndDate = endOfCurrentMonth.toISOString().split('T')[0];

        const _monthlyTotalWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          { startDate: formattedStartDate, endDate: formattedEndDate },
          false,
          'monthly'
        );

        const _monthlyCompletedWaybills = await fetchGraphStatistics(
          'handshakes-daily-distribution',
          { startDate: formattedStartDate, endDate: formattedEndDate },
          true,
          'monthly'
        );

        setMonthlyTotalWaybills(_monthlyTotalWaybills);
        setMonthlyCompletedWaybills(_monthlyCompletedWaybills);
      }
    };

    // Tüm fetch fonksiyonlarını çağırıyoruz
    fetchWeeklyTransactions();
    fetchWeeklyPackages();
    fetchMonthlyTransactions();
    fetchMonthlyPackages();
    fetchReadyToCarry();
    fetchOnPorter();
    fetchCompleteds();
    fetchVehicles();
    fetchCargoVehicles();
    fetchPostsTypes();
    fetchHandshakes();
    fetchWaybills('weekly');
    fetchWaybills('monthly');
    fetchFirms();
  }, []);

  useEffect(() => {
    moment.locale('tr');
    const today = moment();

    const startOfCurrentWeek = moment(today).startOf('week');
    const endOfCurrentWeek = moment(today).endOf('week');

    const weekDaysArray = [];
    for (
      let i = moment(startOfCurrentWeek);
      i <= endOfCurrentWeek;
      i.add(1, 'day')
    ) {
      weekDaysArray.push(moment(i).format('DD MMMM'));
    }
    setWeekDays(weekDaysArray);

    const startOfCurrentMonth = moment(today).startOf('month');
    const endOfCurrentMonth = moment(today).endOf('month');

    const monthDaysArray = [];
    for (
      let i = moment(startOfCurrentMonth);
      i <= endOfCurrentMonth;
      i.add(1, 'day')
    ) {
      monthDaysArray.push(moment(i).format('DD MMMM'));
    }
    setMonthDays(monthDaysArray);
  }, []);

  return (
    <>
      <Helmet>
        <title>{language.dashboard_page.titles.site_title}</title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <AppLineDatas
              title={language.dashboard_page.titles.waybillOperations}
              subheader={language.dashboard_page.titles.weeklyTransactions}
              interval={language.dashboard_page.titles.operations}
              totalWaybills={weeklyTotalWaybills}
              completedWaybills={weeklyCompletedWaybills}
              days={weekDays || []}
              height={250}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AppLineDatas
              title={language.dashboard_page.titles.waybillOperations}
              subheader={language.dashboard_page.titles.monthlyTransactions}
              interval={language.dashboard_page.titles.monthly}
              totalWaybills={monthlyTotalWaybills}
              completedWaybills={monthlyCompletedWaybills}
              days={monthDays || []}
              height={250}
            />
          </Grid>
          {/* Haftalık ve Aylık Taşınanlar */}
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.weeklyOperation}
              total={weeklyTransaction || 0}
              icon={'octicon:graph-24'}
              color="blue"
              state={'increase'}
              comparison="week"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.weeklyPackage}
              total={weeklyPackage || 0}
              color="blue"
              icon={'uil:package'}
              state={'increase'}
              comparison="week"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.monthlyOperation}
              total={monthlyTransaction || 0}
              color="green"
              icon={'octicon:graph-24'}
              state={'decrease'}
              comparison="month"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={language.dashboard_page.titles.monthlyPackage}
              total={monthlyPackage || 0}
              color="green"
              icon={'uil:package'}
              state={'decrease'}
              comparison="month"
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppAllDatas
              title={language.dashboard_page.titles.weeklyOperation}
              open={postsReadyToCarry ? postsReadyToCarry.totalPosts : 0}
              openCount={
                postsReadyToCarry ? postsReadyToCarry.totalPackageCount : 0
              }
              porter={
                transactionsOnCarrying
                  ? transactionsOnCarrying.totalTransactions
                  : 0
              }
              porterCount={
                transactionsOnCarrying
                  ? transactionsOnCarrying.totalPackageCount
                  : 0
              }
              completed={
                transactionsCompleted
                  ? transactionsCompleted.totalTransactions
                  : 0
              }
              completedCount={
                transactionsCompleted
                  ? transactionsCompleted.totalPackageCount
                  : 0
              }
              icon={'octicon:graph-24'}
              color="blue"
              state={'increase'}
              comparison="week"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title={language.dashboard_page.titles.transportationVehicles}
              chartData={
                Object.entries(vehicles)?.length > 0
                  ? Object.entries(vehicles).map(([label, value]) => ({
                      label,
                      value,
                    }))
                  : [{ label: 'Veri Bulunmamaktadır', value: 0 }]
              }
              chartColors={[
                '#49BBDA',
                '#3150A0',
                '#3793AB',
                '#D81B60',
                '#F7A937',
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title={language.dashboard_page.titles.cargoVehicles}
              chartData={
                cargoVehiclesData?.length > 0
                  ? cargoVehiclesData
                  : [{ label: 'Veri bulunmamaktadır', value: 0 }]
              }
              chartColors={[
                '#49BBDA',
                '#3150A0',
                '#3793AB',
                '#F7A937',
                '#D9D9D9',
              ]}
            />
          </Grid>
          {parsedUser.accountCategory !== 'store' && (
            <Grid item xs={12} md={6} lg={3}>
              <AppCurrentVisits
                title={
                  language.dashboard_page.titles.correspondenceAndAcceptance
                }
                chartData={[
                  { label: 'Muhaberat', value: postOfficeCount },
                  { label: 'Mal Kabul', value: acceptGoodsCount },
                ]}
                chartColors={['#F7A937', '#3150A0']}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title={language.dashboard_page.titles.waybillOperations}
              chartData={[
                { label: 'Manuel', value: manuelHandshakes },
                { label: 'QR', value: normalHandshakes },
              ]}
              chartColors={['#F7A937', '#3150A0']}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <AppLineDatas
              title="Yıllık Dağılım"
              // subheader="Her ay için 6 farklı çizgi"
              interval="Yıllık"
              days={firmsLabels || []}
              seriesData={firmsSeries || []}
              height={300}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
