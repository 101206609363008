import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useLocalization } from 'src/context/LocalizationContext';
// ----------------------------------------------------------------------

export default function AppLineDatas({
  title,
  subheader,
  interval,
  height,
  days,
  totalWaybills,
  completedWaybills,
  seriesData,
  totalDatas,
  completedDatas,
  chartLabels,
  chartData,
}) {
  const [selected, setSelected] = useState('Aylık');
  const { language } = useLocalization();

  // seriesData yoksa varsayılan iki seri kullanılıyor.
  const defaultSeries = [
    {
      name: language.components.AppLineDatas.totalWaybill,
      data: totalWaybills,
    },
    {
      name: language.components.AppLineDatas.completedWaybill,
      data: completedWaybills,
    },
  ];

  const currentSeries = seriesData || defaultSeries;

  // Yeni renk paleti: Eğer serilerden fazla varsa, palet içerisinden ilk n renki kullanıyoruz.
  const defaultColors = [
    '#0666F6',
    '#C893FD',
    '#FF5733',
    '#28B463',
    '#F4D03F',
    '#8E44AD',
  ];
  const chartColors = defaultColors.slice(0, currentSeries.length);

  const options = {
    chart: {
      height: height,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: chartColors,
    xaxis: {
      categories: days,
    },
    stroke: {
      curve: 'straight',
      width: 3, // Çizgi kalınlığı
    },
    markers: {
      size: 6, // Marker boyutu
      colors: chartColors, // Marker renkleri
      shape: 'circle',
    },
    // Eğer seriesData varsa Apex'in kendi legend'ını gösteriyoruz
    legend: {
      show: seriesData ? true : false,
    },
  };

  return (
    <Card>
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Stack marginTop="15px" flexDirection="column">
          <Typography
            sx={{
              pl: 2,
              fontSize: 16,
              color: '#98A2B3',
              fontWeight: 400,
            }}
          >
            {subheader}
          </Typography>
          <Typography
            sx={{
              pl: 2,
              fontSize: 22,
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack marginTop="15px" flexDirection="row" alignItems="center">
          {seriesData ? (
            // seriesData varsa tüm serileri ve renklerini gösteriyoruz
            currentSeries.map((item, index) => (
              <Stack key={index} direction="row" alignItems="center" mr={2}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    backgroundColor: chartColors[index],
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                />
                <Typography fontWeight={400} color="#615E83">
                  {item.name}
                </Typography>
              </Stack>
            ))
          ) : (
            // seriesData yoksa mevcut iki serinin bilgilerini gösteriyoruz
            <>
              <div
                style={{
                  height: 10,
                  width: 10,
                  backgroundColor: chartColors[0],
                  borderRadius: 5,
                  marginRight: 10,
                }}
              />
              <Typography fontWeight={400} color="#615E83" mr={2}>
                {interval === 'Changeable'
                  ? language.components.AppLineDatas.averageMonthly
                  : language.components.AppLineDatas.totalWaybill}
              </Typography>
              <div
                style={{
                  height: 10,
                  width: 10,
                  backgroundColor: chartColors[1],
                  borderRadius: 5,
                  marginRight: 10,
                }}
              />
              <Typography fontWeight={400} color="#615E83" mr={2}>
                {interval === 'Changeable'
                  ? language.components.AppLineDatas.totalDelivery
                  : language.components.AppLineDatas.completedWaybill}
              </Typography>
            </>
          )}
          {interval === 'Changeable' && (
            <Stack
              sx={{
                backgroundColor: '#EEF0FB',
                p: 2,
                borderRadius: 1,
                fontWeight: 400,
                color: '#9291A5',
                mr: 2,
                width: 250,
              }}
              justifyContent="space-around"
              alignItems="center"
              flexDirection="row"
            >
              <Typography
                onClick={() => setSelected('Günlük')}
                sx={{
                  cursor: 'pointer',
                  p: 1,
                  borderRadius: 1,
                  backgroundColor:
                    selected === 'Günlük' ? '#172955' : 'transparent',
                  color: selected === 'Günlük' ? 'white' : '#9291A5',
                }}
              >
                {language.components.AppLineDatas.daily}
              </Typography>
              <Typography
                onClick={() => setSelected('Haftalık')}
                sx={{
                  cursor: 'pointer',
                  p: 1,
                  borderRadius: 1,
                  backgroundColor:
                    selected === 'Haftalık' ? '#172955' : 'transparent',
                  color: selected === 'Haftalık' ? 'white' : '#9291A5',
                }}
              >
                {language.components.AppLineDatas.weekly}
              </Typography>
              <Typography
                onClick={() => setSelected('Aylık')}
                sx={{
                  cursor: 'pointer',
                  p: 1,
                  borderRadius: 1,
                  backgroundColor:
                    selected === 'Aylık' ? '#172955' : 'transparent',
                  color: selected === 'Aylık' ? 'white' : '#9291A5',
                }}
              >
                {language.components.AppLineDatas.monthly}
              </Typography>
            </Stack>
          )}
          {interval !== 'Changeable' && (
            <Typography
              sx={{
                backgroundColor: '#EEF0FB',
                p: 1,
                borderRadius: 1,
                fontWeight: 400,
                color: '#9291A5',
                mr: 2,
              }}
            >
              {interval}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Box sx={{ p: 0, pb: 1, height: `${height}px` }} dir="ltr">
        <ReactApexChart
          options={options}
          series={currentSeries}
          type="line"
          height={height}
        />
      </Box>
    </Card>
  );
}
