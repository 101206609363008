import {
    Card, Stack, Typography, TextField, Button,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    Backdrop
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocalization } from 'src/context/LocalizationContext';
import Services from 'src/services/Services';

export default function PostTaskCheckList() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const customer = useSelector((state) => state.customerReducer);

    const { language } = useLocalization();
    const [selectedOption, setSelectedOption] = useState('');
    const [description, setDescription] = useState('');
    const [entries, setEntries] = useState([]);
    // Varsayılan sıralama artık createdAt (tarih) üzerinden, eskiden yeniye (asc) doğru
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [storeOptions, setStoreOptions] = useState([]);
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const [selectedDirection, setSelectedDirection] = useState("");

    const directions = [
        { label: "Mağazadan Depoya", value: "StoreToWarehouse" },
        { label: "Depodan Mağazaya", value: "WarehouseToStore" },
        { label: "Depodan Mal Kabule", value: "WarehouseToMallLogistics" },
        { label: "Mal Kabulden Depoya", value: "MallLogisticsToWarehouse" },
        { label: "Mağazadan Mal Kabule", value: "StoreToMallLogistics" },
        { label: "Mal Kabulden Mağazaya", value: "MallLogisticsToStore" }
    ]

    const statusOptions = [
        { label: 'Tamamlandı', value: 1 },
        { label: 'İptal Edildi', value: 2 },
        { label: 'Bekleniyor', value: 3 },
        { label: 'Taşımacı Atandı', value: 4 },
    ];

    const handleSave = () => {
        if (selectedOption && description) {
            handleCreateTaskCheckList();
            setSelectedOption('');
            setDescription('');
        }
    };

    const handleCreateTaskCheckList = () => {
        setIsBackdropOpen(true)
        Services.CreatePostTaskCheckList(parsedUser.token, customer.customer.id, selectedOption, description, selectedDirection)
            .then((res) => {
                if (res.data.status) {
                    handleGetTaskCheckList();
                }
                else {
                    setIsBackdropOpen(false)
                }
            })
            .catch((err) => console.log(err));
    };

    const handleGetTaskCheckList = () => {
        Services.GetPostTaskCheckList(parsedUser.token, customer?.customer?.id)
            .then((res) => {
                if (res.data.status) {
                    console.log(res.data.result);
                    const fetchedEntries = res.data.result.map(item => ({
                        createdAt: Number(item.createdDateTime) * 1000, // sıralama için
                        date: new Date(Number(item.createdDateTime) * 1000).toLocaleString('tr-TR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        }),
                        description: item.description || "",
                        store: item.branchID,
                        branchTitle: item.branchTitle,
                        status: Number(item.status),
                        taskID: item.taskID,
                        direction: item.direction
                    }));
                    setEntries(fetchedEntries);
                }
                setIsBackdropOpen(false)
            })
            .catch((err) => console.log(err));
    };

    const handleChangeTaskCheckListStatus = (taskID, status) => {
        setIsBackdropOpen(true)
        Services.ChangePostTaskCheckListStatus(parsedUser.token, taskID, status)
            .then((res) => {
                if (res.data.status) {
                    handleGetTaskCheckList()
                }
            })
            .catch((err) => console.log(err));
    }

    const handleRowStatusChange = (index, newValue, taskID) => {
        if (newValue !== 3) {
            handleChangeTaskCheckListStatus(taskID, newValue)
        }

        else {
            toast.warning("Gönderiyi bekleniyor statüsüne çekemezsiniz!")
        }
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedEntries = useMemo(() => {
        return entries.slice().sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [entries, order, orderBy]);

    useEffect(() => {
        setIsBackdropOpen(true);
        if (customer.customer) {
            // handleGetTaskCheckList fonksiyonunu promise döndürecek şekilde güncelliyoruz.
            const taskCheckListPromise = Services.GetPostTaskCheckList(parsedUser.token, customer?.customer?.id)
                .then((res) => {
                    if (res.data.status) {
                        const fetchedEntries = res.data.result.map(item => ({
                            createdAt: Number(item.createdDateTime) * 1000, // sıralama için
                            date: new Date(Number(item.createdDateTime) * 1000).toLocaleString('tr-TR', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }),
                            description: item.description || "",
                            store: item.branchID,
                            branchTitle: item.branchTitle,
                            status: Number(item.status),
                            taskID: item.taskID,
                            direction: item.direction
                        }));
                        setEntries(fetchedEntries);
                    }
                })
                .catch((err) => console.log(err));

            const getStoresPromise = Services.GetStores(parsedUser.token, customer.customer.id)
                .then((res) => {
                    if (res.data.result) {
                        const options = res.data.result.map(item => ({
                            label: item.title,
                            value: item.branchID,
                        }));
                        setStoreOptions(options);
                    }
                })
                .catch((err) => console.log(err));

            Promise.all([taskCheckListPromise, getStoresPromise])
                .finally(() => setIsBackdropOpen(false));
        }
    }, [customer]);


    return (
        <>
            <Helmet>
                <title>{language.post_task_cheklist.titles.site_title}</title>
            </Helmet>

            <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack sx={{ paddingInline: '25px' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {language.post_task_cheklist.titles.title}
                    </Typography>
                </Stack>
                <Card sx={{ mb: 2 }}>
                    <Stack direction="row" spacing={2} padding={2}>
                        <Autocomplete
                            fullWidth
                            options={storeOptions}
                            value={storeOptions.find(option => option.value === selectedOption) || null}
                            onChange={(event, newValue) => setSelectedOption(newValue ? newValue.value : '')}
                            renderInput={(params) => (
                                <TextField {...params} label="Mağaza" variant="outlined" />
                            )}
                        />
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Yön</InputLabel>
                            <Select
                                value={selectedDirection}
                                label="Yön"
                                onChange={(event) =>
                                    setSelectedDirection(event.target.value)
                                }
                            >
                                {directions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Açıklama"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={1}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleSave}>
                            Kaydet
                        </Button>
                    </Stack>
                </Card>
                {entries.length > 0 && (
                    <Card>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/* Sütunlar: Tarih, Açıklama, Mağaza, Durum */}
                                        <TableCell sx={{ width: '20%' }}>
                                            <TableSortLabel
                                                active={orderBy === 'createdAt'}
                                                direction={orderBy === 'createdAt' ? order : 'asc'}
                                                onClick={() => handleRequestSort('createdAt')}
                                            >
                                                Tarih
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell sx={{ width: '40%' }}>
                                            <TableSortLabel
                                                active={orderBy === 'description'}
                                                direction={orderBy === 'description' ? order : 'asc'}
                                                onClick={() => handleRequestSort('description')}
                                            >
                                                Açıklama
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%' }}>
                                            <TableSortLabel
                                                active={orderBy === 'store'}
                                                direction={orderBy === 'store' ? order : 'asc'}
                                                onClick={() => handleRequestSort('store')}
                                            >
                                                Mağaza
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            <TableSortLabel
                                                active={orderBy === 'store'}
                                                direction={orderBy === 'store' ? order : 'asc'}
                                                onClick={() => handleRequestSort('store')}
                                            >
                                                Yön
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%' }}>
                                            <TableSortLabel
                                                active={orderBy === 'status'}
                                                direction={orderBy === 'status' ? order : 'asc'}
                                                onClick={() => handleRequestSort('status')}
                                            >
                                                Durum
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedEntries.map((entry, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{entry.date}</TableCell>
                                            <TableCell>{entry.description}</TableCell>
                                            <TableCell>{entry.branchTitle}</TableCell>
                                            <TableCell>{directions?.find(x => x.value === entry.direction)?.label ? directions?.find(x => x.value === entry.direction)?.label : "-"}</TableCell>
                                            <TableCell>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id={`status-select-label-${index}`}>Durum</InputLabel>
                                                    <Select
                                                        labelId={`status-select-label-${index}`}
                                                        id={`status-select-${index}`}
                                                        value={entry.status}
                                                        label="Durum"
                                                        onChange={(event) =>
                                                            handleRowStatusChange(index, event.target.value, entry.taskID)
                                                        }
                                                    >
                                                        {statusOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                )}
            </Stack>
        </>
    );
}
