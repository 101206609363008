import React, { useState, useEffect, useRef } from 'react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Typography,
  Checkbox,
  FormControlLabel,
  ButtonBase,
  Stack,
  InputAdornment,
  Autocomplete
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import api from 'src/services/BaseService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocalization } from 'src/context/LocalizationContext';
import Services from 'src/services/Services';


const AddEditAvmModal = ({
  open,
  onClose,
  isEdit,
  editData,
  mallList,
  isBackdropOpen
}) => {
  const { language } = useLocalization();
  const textAreaRef = useRef(null);
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [checkRoomExist, setCheckRoomExist] = useState(false);
  const [dropBoxExist, setDropBoxExist] = useState(false);
  const [checkroomLocations, setCheckroomLocations] = useState([]);
  const [dropboxLocations, setDropboxLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);

  const [selectedCheckRoomLocations, setSelectedCheckRoomLocations] = useState([]);
  const [selectedDropBoxLocations, setSelectedDropBoxLocations] = useState([]);

  const [isCheckroomSet, setIsCheckroomSet] = useState(false);
  const [isDropboxSet, setIsDropboxSet] = useState(false);
  const [transportationTools, setTransportationTools] = useState([]);

  let transportationToolsWithPrice = [];

  const [formData, setFormData] = useState({
    createAvmTitle: '', // AVM adı
    city: '',           // Şehir
    county: '',         // İlçe
    hasMuhaberat: false, // Muhaberat kontrolü
  });

  const cityList = [
    { id: 1, name: language.commons.cities.adana },
    { id: 2, name: language.commons.cities.adıyaman },
    { id: 3, name: language.commons.cities.afyonkarahisar },
    { id: 4, name: language.commons.cities.ağrı },
    { id: 5, name: language.commons.cities.amasya },
    { id: 6, name: language.commons.cities.ankara },
    { id: 7, name: language.commons.cities.antalya },
    { id: 8, name: language.commons.cities.artvin },
    { id: 9, name: language.commons.cities.aydın },
    { id: 10, name: language.commons.cities.balıkesir },
    { id: 11, name: language.commons.cities.bilecik },
    { id: 12, name: language.commons.cities.bingöl },
    { id: 13, name: language.commons.cities.bitlis },
    { id: 14, name: language.commons.cities.bolu },
    { id: 15, name: language.commons.cities.burdur },
    { id: 16, name: language.commons.cities.bursa },
    { id: 17, name: language.commons.cities.çanakkale },
    { id: 18, name: language.commons.cities.çankırı },
    { id: 19, name: language.commons.cities.çorum },
    { id: 20, name: language.commons.cities.denizli },
    { id: 21, name: language.commons.cities.diyarbakır },
    { id: 22, name: language.commons.cities.edirne },
    { id: 23, name: language.commons.cities.elazığ },
    { id: 24, name: language.commons.cities.erzincan },
    { id: 25, name: language.commons.cities.erzurum },
    { id: 26, name: language.commons.cities.eskişehir },
    { id: 27, name: language.commons.cities.gaziantep },
    { id: 28, name: language.commons.cities.giresun },
    { id: 29, name: language.commons.cities.gümüşhane },
    { id: 30, name: language.commons.cities.hakkari },
    { id: 31, name: language.commons.cities.hatay },
    { id: 32, name: language.commons.cities.ısparta },
    { id: 33, name: language.commons.cities.mersin },
    { id: 34, name: language.commons.cities.istanbul },
    { id: 35, name: language.commons.cities.izmir },
    { id: 36, name: language.commons.cities.kars },
    { id: 37, name: language.commons.cities.kastamonu },
    { id: 38, name: language.commons.cities.kayseri },
    { id: 39, name: language.commons.cities.kırklareli },
    { id: 40, name: language.commons.cities.kırşehir },
    { id: 41, name: language.commons.cities.kocaeli },
    { id: 42, name: language.commons.cities.konya },
    { id: 43, name: language.commons.cities.kütahya },
    { id: 44, name: language.commons.cities.malatya },
    { id: 45, name: language.commons.cities.manisa },
    { id: 46, name: language.commons.cities.kahramanmaraş },
    { id: 47, name: language.commons.cities.mardin },
    { id: 48, name: language.commons.cities.muğla },
    { id: 49, name: language.commons.cities.muş },
    { id: 50, name: language.commons.cities.nevşehir },
    { id: 51, name: language.commons.cities.niğde },
    { id: 52, name: language.commons.cities.ordu },
    { id: 53, name: language.commons.cities.rize },
    { id: 54, name: language.commons.cities.sakarya },
    { id: 55, name: language.commons.cities.samsun },
    { id: 56, name: language.commons.cities.siirt },
    { id: 57, name: language.commons.cities.sinop },
    { id: 58, name: language.commons.cities.sivas },
    { id: 59, name: language.commons.cities.tekirdağ },
    { id: 60, name: language.commons.cities.tokat },
    { id: 61, name: language.commons.cities.trabzon },
    { id: 62, name: language.commons.cities.tunceli },
    { id: 63, name: language.commons.cities.şanlıurfa },
    { id: 64, name: language.commons.cities.uşak },
    { id: 65, name: language.commons.cities.van },
    { id: 66, name: language.commons.cities.yozgat },
    { id: 67, name: language.commons.cities.zonguldak },
    { id: 68, name: language.commons.cities.aksaray },
    { id: 69, name: language.commons.cities.bayburt },
    { id: 70, name: language.commons.cities.karaman },
    { id: 71, name: language.commons.cities.kırıkkale },
    { id: 72, name: language.commons.cities.batman },
    { id: 73, name: language.commons.cities.şırnak },
    { id: 74, name: language.commons.cities.bartın },
    { id: 75, name: language.commons.cities.ardahan },
    { id: 76, name: language.commons.cities.ığdır },
    { id: 77, name: language.commons.cities.yalova },
    { id: 78, name: language.commons.cities.karabük },
    { id: 79, name: language.commons.cities.kilis },
    { id: 80, name: language.commons.cities.osmaniye },
    { id: 81, name: language.commons.cities.düzce }
  ];

  useEffect(() => {
    // API çağrısı
    Services.GetTransportationTools(parsedUser.token)
      .then((response) => {
        if (response.data.status) {
          setTransportationTools(response.data.result); // Dinamik araç listesini set edin
        } else {
          console.error("Araç listesi alınamadı.");
        }
      })
      .catch((error) => {
        console.error("API çağrısı sırasında hata:", error);
      });
  }, []);

  const handleSave = () => {
    let isPrimaryBoolean;
    if (formData.hasMuhaberat === false) {
      isPrimaryBoolean = '0';
    } else {
      isPrimaryBoolean = '1';
    }
    transportationToolsWithPrice = transportationTools
      .filter((tool) => formData[`is${tool.title.replace(/ /g, '')}`])
      .map((tool) => ({
        title: tool.title,
        price: formData[`${tool.title.replace(/ /g, '')}Price`],
        currency: formData[`${tool.title.replace(/ /g, '')}Currency`],
      }));


    //const selectedAvm = mallList.find((mall) => mall.id === formData.mallID);

    if (isEdit) {
      const requestBody = {
        id: editData.id,
        title: formData.createAvmTitle,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,
        transportationToolsWithPrice,
        canViewBoxMenu: dropBoxExist ? "1" : "0",
        canViewCheckroomMenu: checkRoomExist ? "1" : "0",
        boxList: selectedDropBoxLocations,
        checkroomList: selectedCheckRoomLocations
      };
      //console.log("requestBody", requestBody);
      api
        .post('/flow/malls-update', requestBody)
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data)
            setResultMessage('Mağaza başarıyla Düzenlendi!');
            setShowResultMessage(true);
          } else {
            // console.log("error",response)
            setResultMessage('Mağaza Düzenlenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
      // console.log("requestBody", requestBody);
    } else {
      const requestBody = {
        title: formData.createAvmTitle,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,
        transportationToolsWithPrice,
        canViewBoxMenu: dropBoxExist,
        canViewCheckroomMenu: checkRoomExist,
        boxList: selectedDropBoxLocations,
        checkroomList: selectedCheckRoomLocations
      };

      console.log({
        title: formData.createAvmTitle,
        city: formData.city,
        county: formData.county,
        hasMuhaberat: formData.hasMuhaberat,
        transportationToolsWithPrice,
        canViewBoxMenu: dropBoxExist,
        canViewCheckroomMenu: checkRoomExist,
        boxList: selectedDropBoxLocations,
        checkroomList: selectedCheckRoomLocations
      });
      api
        .post('/flow/malls-create', requestBody)
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            // console.log(response.data)
            setResultMessage('Mağaza başarıyla Eklendi!');
            setShowResultMessage(true);
          } else {
            // console.log("error", response)
            setResultMessage('Mağaza Eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
      // console.log("requestBody", requestBody);
    }
  };

  const allFieldsAreValid = () => {

    if (dropBoxExist && (!selectedDropBoxLocations || selectedDropBoxLocations.length === 0)) {
      return false;
    }

    if (checkRoomExist && (!selectedCheckRoomLocations || selectedCheckRoomLocations.length === 0)) {
      return false;
    }

    if (isEdit) {
      if (
        formData.mallID !== '' &&
        formData.city !== '' &&
        formData.county !== '' &&
        formData.createAvmTitle !== ''
      ) {
        return true;
      } else return false;
    }

    else {
      if (
        formData.createAvmTitle !== '' &&
        formData.city !== '' &&
        formData.county !== ''
      ) {
        return true;
      } else return false;
    }
  };

  const handleMallChange = async (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      mallID: value,
    }));
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === 'county' || name === 'createAvmTitle') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      return;
    }
  };
  const handleSwitchChange = () => {
    const { name, value } = event.target;

    if (name === 'hasMuhaberat') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.hasMuhaberat,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCheckroomSwitchChange = () => {
    setCheckRoomExist(!checkRoomExist)
    setSelectedCheckRoomLocations([])
  }
  const handleDropboxSwitchChange = () => {
    setDropBoxExist(!dropBoxExist)
    setSelectedDropBoxLocations([])
  }

  const handleCityChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      city: value,
    }));
  };

  const handleClose = () => {
    setFormData({});
    onClose();
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };
  const handleCargoChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'transportationTool') {
      setFormData((prevState) => ({
        ...prevState,
        transportationTool: value,
      }));
    } else if (
      name === 'transportationPrice' ||
      name === 'parcelCargoPrice' ||
      name === 'smallToolPrice' ||
      name === 'bigToolPrice' ||
      name === 'suspendToolPrice' ||
      name === 'palletPrice' ||
      name === 'isMiddlePrice' ||
      name === 'standartVehiclePrice' ||
      name === 'waterSellPrice'
    ) {
      let formattedValue = value.replace(/,/g, '.');
      if (formattedValue.split('.').length > 2) {
        formattedValue = formattedValue.slice(0, formattedValue.length - 1);
      }
      if (/^[0-9]*\.?[0-9]*$/.test(formattedValue)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else if (
      name === 'transportationCurrency' ||
      name === 'parcelCargoCurrency' ||
      name === 'smallToolCurrency' ||
      name === 'bigToolCurrency' ||
      name === 'suspendToolCurrency' ||
      name === 'palletCurrency' ||
      name === 'isMiddleCurrency' ||
      name === 'standartVehicleCurrency' ||
      name === 'waterSellCurrency'
    ) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDeleteChip = (type, value) => {
    if (type === 'checkroom') {
      setSelectedCheckRoomLocations((prev) => prev.filter((item) => item !== value));
    } else if (type === 'dropbox') {
      setSelectedDropBoxLocations((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleIdCopy = (e) => {
    const value = textAreaRef.current.querySelector('input').value;
    navigator.clipboard.writeText(value)
    e.target.focus();

  };

  useEffect(() => {
    if (isEdit && transportationTools.length > 0) {

      const updatedFormData = transportationTools?.reduce((acc, tool) => {
        const toolKey = tool.title.replace(/ /g, '');
        const toolData = (Array.isArray(editData?.transportation_tool_with_price)
        ? editData.transportation_tool_with_price
        : []
      ).find((item) => item.title === tool.title);

        acc[`is${toolKey}`] = !!toolData;
        acc[`${toolKey}Price`] = toolData ? toolData.price : '';
        acc[`${toolKey}Currency`] = toolData ? toolData.currency : '';

        return acc;
      }, {});
      console.log("updatedFormData:", updatedFormData);

      setFormData((prevState) => ({
        ...prevState,
        ...updatedFormData,
        createAvmTitle: editData.title,
        city: editData.city,
        county: editData.county,
        hasMuhaberat: editData.hasMuhaberat === '1',
      }));
    }
  }, [isEdit, transportationTools]);


  useEffect(() => {
    Promise.all([
      Services.GetDropBoxLocations(parsedUser.token),
      Services.GetCheckroomLocations(parsedUser.token),
      isEdit && Services.GetMallInformation(parsedUser.token, editData.id)
    ])
      .then(([dropBoxRes, checkroomRes, mallInformationRes]) => {
        if (dropBoxRes.data.status) {
          setDropboxLocations(dropBoxRes.data.result);
          setIsDropboxSet(true)
        }

        if (checkroomRes.data.status) {
          setCheckroomLocations(checkroomRes.data.result);
          setIsCheckroomSet(true); // İlk işlem tamamlandığında işareti güncelle
        }

        if (isEdit) {
          if (mallInformationRes.data.result.canViewCheckroomMenu === "1") {
            setCheckRoomExist(true);
          }

          if (mallInformationRes.data.result.canViewBoxMenu === "1") {
            setDropBoxExist(true);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching locations:", err);
      })
      .finally(() => {
        setLocationsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isCheckroomSet && isDropboxSet && isEdit) {
      Services.GetMallInformation(parsedUser.token, editData.id).then((mallInformationRes) => {
        setSelectedCheckRoomLocations(mallInformationRes.data.result.checkroomList);
        setSelectedDropBoxLocations(mallInformationRes.data.result.boxList)
      });
    }
  }, [isCheckroomSet, isDropboxSet]);



  return (
    <>
      <Dialog open={open} onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: {
              xs: '80%', // Küçük ekranlar için genişlik
              sm: '60%', // Orta ekranlar için genişlik
              md: '50%', // Büyük ekranlar için genişlik
            },
            maxWidth: '100%',
          },
        }}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen || locationsLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogTitle>{language.components.AddEditAvmModal.title}</DialogTitle>

        <DialogContent>
          <>
            {isEdit === true ? (
              <FormControl fullWidth margin="normal">
                <TextField
                  fullWidth
                  label={language.commons.column.mall_id}
                  name="avmListId"
                  value={editData.id}
                  margin="normal"
                  readOnly
                  ref={textAreaRef}
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={handleIdCopy}>
                          <ContentCopyIcon color="action" />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />


              </FormControl>
            ) : ''

            }

            <FormControl fullWidth margin="normal">

              <TextField
                fullWidth
                label="AVM/Plaza İsmi*"
                name="createAvmTitle"
                value={formData.createAvmTitle}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>{language.commons.city}*</InputLabel>
              <Select
                sx={{ width: '100%' }}
                label={language.commons.city + '*'}
                value={formData.city}
                onChange={handleCityChange}
                name="city"
              >
                {cityList.map((city, index) => (
                  <MenuItem key={index} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={language.commons.state + '*'}
              name="county"
              value={formData.county}
              onChange={handleTextFieldChange}
              margin="normal"
            />

            {transportationTools.map((tool, index) => (
              <Stack
                key={tool.id}
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                paddingY={1}
                paddingX={1}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!formData[`is${tool.title.replace(/ /g, '')}`]}
                      onChange={(e) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          [`is${tool.title.replace(/ /g, '')}`]: e.target.checked,
                        }))
                      }
                      name={`is${tool.title.replace(/ /g, '')}`}
                    />
                  }
                  label={
                    <ButtonBase
                      onClick={() =>
                        setFormData((prevState) => ({
                          ...prevState,
                          [`is${tool.title.replace(/ /g, '')}`]: !formData[`is${tool.title.replace(/ /g, '')}`],
                        }))
                      }
                      sx={{
                        borderRadius: 0,
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Typography>{tool.title}</Typography>
                    </ButtonBase>
                  }
                  sx={{ flex: 1.3 }}
                />
                <TextField
                  fullWidth
                  label={`${language.commons.price}*`}
                  name={`${tool.title.replace(/ /g, '')}Price`}
                  value={formData[`${tool.title.replace(/ /g, '')}Price`] || ''}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      [`${tool.title.replace(/ /g, '')}Price`]: e.target.value,
                    }))
                  }
                  margin="normal"
                  sx={{ flex: 2 }}
                  disabled={!formData[`is${tool.title.replace(/ /g, '')}`]}
                />
                <FormControl fullWidth margin="normal" sx={{ flex: 1.2 }}>
                  <InputLabel>{language.commons.currency}*</InputLabel>
                  <Select
                    label={`${language.commons.currency}*`}
                    value={formData[`${tool.title.replace(/ /g, '')}Currency`] || ''}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        [`${tool.title.replace(/ /g, '')}Currency`]: e.target.value,
                      }))
                    }
                    name={`${tool.title.replace(/ /g, '')}Currency`}
                    disabled={!formData[`is${tool.title.replace(/ /g, '')}`]}
                  >
                    <MenuItem value="TRY">{language.commons.currencies.tl}</MenuItem>
                    <MenuItem value="EURO">{language.commons.currencies.euro}</MenuItem>
                    <MenuItem value="USD">{language.commons.currencies.dolar}</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            ))}


            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 10,
              }}
            >

              <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}>
                <Typography>{language.components.AddEditAvmModal.is_muhaberat}:</Typography>
                <Switch
                  checked={formData.hasMuhaberat}
                  onChange={handleSwitchChange}
                  name="hasMuhaberat"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>

              {/* <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}>
                <Typography>{language.components.AddEditAvmModal.is_handsfree}:</Typography>
                <Switch
                  checked={formData.hasMuhaberat}
                  onChange={handleSwitchChange}
                  name="hasMuhaberat"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div> */}

              <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}><Typography>Vestiyer Hizmeti:</Typography>
                <Switch
                  checked={checkRoomExist}
                  onChange={handleCheckroomSwitchChange}
                  name="checkRoomExist"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /></div>

              <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: 10,
              }}><Typography>Emanet Hizmeti:</Typography>
                <Switch
                  checked={dropBoxExist}
                  onChange={handleDropboxSwitchChange}
                  name="dropBoxExist"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /></div>

            </div>

            {checkRoomExist && (
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  options={checkroomLocations}
                  getOptionLabel={(option) => `${option.locationCode} - ${option.name}`}
                  value={selectedCheckRoomLocations.map((code) =>
                    checkroomLocations.find((loc) => loc.locationCode === code)
                  )}
                  onChange={(event, newValue) =>
                    setSelectedCheckRoomLocations(newValue.map((item) => item.locationCode))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Vestiyer Lokasyonları" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.locationCode === value.locationCode
                  }
                />
              </div>
            )}

            {dropBoxExist && (
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  options={dropboxLocations}
                  getOptionLabel={(option) => `${option.locationCode} - ${option.name}`}
                  value={selectedDropBoxLocations?.map((code) =>
                    dropboxLocations.find((loc) => loc.locationCode === code)
                  )}
                  onChange={(event, newValue) =>
                    setSelectedDropBoxLocations(newValue.map((item) => item.locationCode))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Emanet Lokasyonları" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.locationCode === value.locationCode
                  }
                />
              </div>
            )}

          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {language.commons.back}
          </Button>
          {isEdit === true ? (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!allFieldsAreValid() || transportationTools.every((tool) => !formData[`is${tool.title.replace(/ /g, '')}`])}
            >
              {language.commons.save}
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!allFieldsAreValid() || transportationTools.every((tool) => !formData[`is${tool.title.replace(/ /g, '')}`])}
            >
              {language.commons.add}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isEdit === true ? (
          <DialogTitle id="alert-dialog-title">
            {language.commons.store_update}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{language.commons.store_add}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>{language.commons.okay}</Button>{' '}
          {/* Fonksiyon olarak geçirildi */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditAvmModal;
