import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  Checkbox,
  FormControl,
  TextField,
  Select,
  MenuItem,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Helmet } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import DynamicTable from 'src/components/shared/DynamicTable';
import { useLocalization } from 'src/context/LocalizationContext';
import Services from 'src/services/Services';
import { tokenService } from 'src/services';
import MultipleFileUpload from 'src/components/shared/MultipleFileUpload';
import { toast } from 'react-toastify';
import api from 'src/services/BaseService';

export default function CollectionTransactions() {
  const { language } = useLocalization();
  const token = tokenService.getUser()?.token;
  const mallID = localStorage.getItem('mallID');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState(null);

  const [stores, setStores] = useState([]);

  const [selectedStoreForFilter, setSelectedStoreForFilter] = useState(null);
  const [receiptNumberFilter, setReceiptNumberFilter] = useState('');
  const [dateFilter, setDateFilter] = useState(null);
  const [receiptData, setReceiptData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [selectedReceiptID, setSelectedReceiptID] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [amount, setAmount] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');

  const [files, setFiles] = useState([]);
  const [isDeposited, setIsDeposited] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);

  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedAttachmentFiles, setSelectedAttachmentFiles] = useState([]);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [fileIndexToDelete, setFileIndexToDelete] = useState(null);

  const isMobileScreen = useMediaQuery('(max-width:768px)');

  const transformedData = receiptData?.map((row) => ({
    ...row,
    id: row.receiptID,
  }));

  const TABLE_HEAD = [
    {
      id: 'checkbox',
      label: '',
      alignRight: false,
      format: (value, row, selected, handleCheckboxChange) => (
        <Checkbox
          color="primary"
          checked={selected.indexOf(row.receiptID) !== -1}
          onChange={(e) => handleCheckboxChange(e, row.receiptID, row)}
          inputProps={{ 'aria-label': 'select row checkbox' }}
        />
      ),
      sx: { width: 50 },
    },
    {
      id: 'attachments',
      label: '',
      align: 'center',
      format: (value, row) =>
        row.files && row.files.length > 0 ? (
          <IconButton onClick={() => handleOpenAttachmentModal(row)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      sx: { width: 50 },
    },
    {
      id: 'title',
      label: 'Mağaza Adı',
      alignRight: false,
    },
    {
      id: 'receiptNo',
      label: 'Makbuz No',
      alignRight: false,
    },
    {
      id: 'receiptDate',
      label: 'Tarih',
      alignRight: false,
      format: (value) => {
        if (!value) return '-';
        const date = new Date(value * 1000);
        return date.toLocaleDateString('tr-TR');
      },
    },
    {
      id: 'price',
      label: 'Tutar',
      alignRight: false,
      format: (value, row) => {
        return `${row.price} ${
          row.currency === 'TRY'
            ? '₺'
            : row.currency === 'USD'
            ? '$'
            : row.currency === 'EUR'
            ? '€'
            : row.currency
        }`;
      },
    },
    {
      id: 'isPaid',
      label: 'Hesaba Yatırıldı mı?',
      align: 'center',
      format: (value) => (value === '1' ? 'Evet' : 'Hayır'),
      sx: { textAlign: 'center' },
    },
    {
      id: 'operation',
      label: 'Düzenle',
      alignRight: false,
      format: (value, row) => (
        <Button variant="outlined" size="small" onClick={() => handleEdit(row)}>
          Düzenle
        </Button>
      ),
      sx: { width: 100 },
    },
  ];

  const handleCheckboxChange = (event, receiptID, row) => {
    const isChecked = event.target.checked;
    setSelected((prevSelected) =>
      isChecked
        ? [...prevSelected, receiptID]
        : prevSelected.filter((id) => id !== receiptID)
    );
  };
  const handleOpenAttachmentModal = (row) => {
    setSelectedAttachmentFiles(row.files);
    setAttachmentModalOpen(true);
  };

  const handleCloseAttachmentModal = () => {
    setAttachmentModalOpen(false);
    setSelectedAttachmentFiles([]);
  };

  const handleExportToExcel = () => {
    let temp1 = receiptData?.map((x, index) => {
      let receiptDate = moment.unix(x.receiptDate).format('DD/MM/YYYY HH:mm');
      let isPaid = x.isPaid === '1' ? 'Evet' : 'Hayır';

      let a = {
        id: index + 1,
        title: x.title,
        receiptNo: x.receiptNo,
        receiptDate: receiptDate,
        price: `${x.price} ${x.currency}`,
        isPaid: isPaid,
        files: x?.files ? x.files.join(', ') : '',
      };
      return a;
    });

    // console.log('excel: ', temp1);

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          ID: 'number',
          'Mağaza Adı': 'string',
          'Makbuz No': 'string',
          Tarih: 'string',
          Tutar: 'string',
          'Hesaba Yatırıldı Mı?': 'string',
          Dosyalar: 'string',
        },
        sheetTitle: 'Tahsilat İşlemleri',
      })
      .then((response) => {
        if (response.data.status) {
          window.open(response.data.result, '_blank');
        } else {
          toast.error('Bir hata oluştu');
        }
      })
      .catch((error) => {
        toast.error('Bir hata oluştu');
      });
  };

  const handleCreateCollection = () => {
    setEditMode(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedStore(null);
    setSelectedReceiptID('');
    setSelectedDate(null);
    setAmount('');
    setReceiptNumber('');
    setFiles([]);
    setExistingFiles([]);
    setIsDeposited(false);
    setOpenDialog(false);
  };

  const handleSubmitDialog = () => {
    setSubmitting(true);

    if (editMode) {
      Services.updateReceipts(
        token,
        selectedReceiptID,
        selectedStore,
        receiptNumber,
        selectedDate?.format('YYYY-MM-DD'),
        amount,
        isDeposited === 'true' || isDeposited === true ? true : false,
        existingFiles,
        files?.map((file) => file.base64.base64) || []
      )
        .then((res) => {
          if (res.data.status) {
            toast.success('İşleminiz gerçekleştirildi.');
            fetchReceipts();
            handleCloseDialog();
          } else {
            toast.error('İşleminiz gerçekleştirilirken bir hata oluştu.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('İşleminiz gerçekleştirilirken bir hata oluştu.');
        })
        .finally(() => setSubmitting(false));
    } else {
      Services.CreateReceipts(
        token,
        selectedStore,
        receiptNumber,
        selectedDate?.format('YYYY-MM-DD') || null,
        amount,
        isDeposited === 'true' || isDeposited === true ? true : false,
        files.map((file) => file.base64.base64)
      )
        .then((res) => {
          if (res.data.status) {
            toast.success('İşleminiz gerçekleştirildi.');
            fetchReceipts();
            handleCloseDialog();
          } else {
            toast.error('İşleminiz gerçekleştirilirken bir hata oluştu.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('İşleminiz gerçekleştirilirken bir hata oluştu.');
        })
        .finally(() => setSubmitting(false));
    }
  };

  const fetchReceipts = (clean = false) => {
    setLoading(true);
    const dateString = dateFilter ? dateFilter.format('YYYY-MM-DD') : null;

    Services.GetPaymentReceipts(
      token,
      mallID,
      clean ? null : selectedStoreForFilter,
      clean ? null : receiptNumberFilter,
      clean ? null : dateString
    )
      .then((res) => {
        if (res.data?.result) {
          console.log(res.data.result);
          setReceiptData(res.data.result);
        } else {
          setReceiptData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setReceiptData([]);
      })
      .finally(() => setLoading(false));
  };

  const clearFilters = () => {
    setSelectedStoreForFilter(null);
    setSelectedReceiptID('');
    setReceiptNumberFilter('');
    setDateFilter(null);
    fetchReceipts(true);
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleDeleteExistingFile = (index) => {
    setFileIndexToDelete(index);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = () => {
    setExistingFiles((prev) => prev.filter((_, i) => i !== fileIndexToDelete));
    setDeleteConfirmOpen(false);
  };

  const cancelDelete = () => {
    setDeleteConfirmOpen(false);
    setFileIndexToDelete(null);
  };

  const handleConfirmAction = () => {
    Services.SetPaymentReceiptsStatus(
      token,
      selected,
      action === 'isPaid' ? true : false
    )
      .then((res) => {
        if (res.data.status) {
          toast.success('İşleminiz gerçekleştirilmiştir.');
          fetchReceipts();
          setSelected([]);
        } else {
          toast.error('İşleminiz gerçekleştirilirken bir hata oluştu.');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('İşleminiz gerçekleştirilirken bir hata oluştu.');
      });
  };

  const handleEdit = (row) => {
    setSelectedReceiptID(row.receiptID);
    setSelectedStore(row.branchID);
    setSelectedDate(moment(row.receiptDate * 1000));
    setAmount(row.price);
    setReceiptNumber(row.receiptNo);
    setFiles([]);
    setExistingFiles(row.files || []);
    setIsDeposited(row.isPaid === '1');
    setEditMode(true);
    setOpenDialog(true);
  };

  useEffect(() => {
    fetchReceipts();
    Services.GetStores(token, mallID)
      .then((res) => {
        if (res.data.status) {
          setStores(res.data.result);
        } else {
          setStores([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setStores([]);
      });
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Helmet>
        <title> Tahsilat İşlemleri | Mall Logistics Panel </title>
      </Helmet>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h4">Tahsilat İşlemleri</Typography>
        <Stack>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCollection}
          >
            Tahsilat Oluştur
          </Button>
        </Stack>
      </Stack>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: 200, marginTop: 20 }}
        >
          <CircularProgress style={{ color: '#103996' }} />
        </Box>
      ) : (
        <Card>
          <Stack
            direction="row"
            alignItems="center"
            display="flex"
            justifyContent="space-evenly"
            paddingTop={5}
            paddingX={2}
            spacing={2}
          >
            <div style={{ display: 'flex', flex: 5, gap: 20 }}>
              <FormControl sx={{ flex: 1 }} variant="outlined">
                <InputLabel id="store-select-label">Mağaza Seçin</InputLabel>
                <Select
                  labelId="store-select-label"
                  value={selectedStoreForFilter}
                  onChange={(e) => setSelectedStoreForFilter(e.target.value)}
                  label="Mağaza Seçin"
                >
                  <MenuItem value="">
                    <em>Hepsi</em>
                  </MenuItem>
                  {stores?.map((store) => (
                    <MenuItem key={store.branchID} value={store.branchID}>
                      {store.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <TextField
                  label={language.commons.column.receipt_number}
                  variant="outlined"
                  value={receiptNumberFilter}
                  onChange={(e) => setReceiptNumberFilter(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Tarih Seçiniz"
                    value={dateFilter}
                    onChange={(newValue) => setDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              flex={2}
            >
              <Button
                sx={{
                  marginRight: '8px',
                  color: 'black',
                  borderColor: 'black',
                  fontWeight: '500',
                  '&:hover': {
                    borderColor: 'black',
                  },
                }}
                variant="outlined"
                onClick={clearFilters}
              >
                {language.commons.clear}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2C7EF8',
                  fontWeight: '500',
                }}
                variant="contained"
                onClick={() => fetchReceipts(false)}
              >
                {language.commons.filter}
              </Button>
              <Button
                sx={{ marginLeft: '8px' }}
                variant="outlined"
                color="primary"
                onClick={handleExportToExcel}
              >
                Excel'e Aktar
              </Button>
            </Stack>
          </Stack>

          <DynamicTable
            data={transformedData}
            headers={TABLE_HEAD}
            selected={selected}
            handleCheckboxChange={handleCheckboxChange}
            showCheckbox={true}
          />

          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={TABLE_HEAD.length}
                    sx={{ p: 0, border: 0 }}
                  >
                    <Box sx={{ backgroundColor: 'white', p: 2 }}>
                      <Stack
                        direction={isMobileScreen ? 'column' : 'row'}
                        alignItems="center"
                        spacing={2}
                        style={{
                          backgroundColor: '#F4F4F4',
                          padding: '10px',
                          border: 'solid 1px',
                          borderColor: '#EBEBEB',
                          borderRadius: 6,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#3F4755',
                          }}
                        >
                          Seçilenleri toplu düzenle:
                        </Typography>

                        <FormControl sx={{ minWidth: 200 }}>
                          <Select
                            displayEmpty
                            value={action}
                            onChange={handleActionChange}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <em>İşlem Seçin</em>;
                              }
                              return selected === 'isPaid'
                                ? 'Hesaba yatırıldı'
                                : 'Hesaba yatırılmadı';
                            }}
                            sx={{ backgroundColor: 'white' }}
                          >
                            <MenuItem value="">
                              <em>İşlem Seçin</em>
                            </MenuItem>
                            <MenuItem value={'isPaid'}>
                              Hesaba yatırıldı
                            </MenuItem>
                            <MenuItem value={'isNotPaid'}>
                              Hesaba yatırılmadı
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <Button
                          variant="contained"
                          color="primary"
                          disabled={selected?.length === 0}
                          onClick={handleConfirmAction}
                          sx={{ minWidth: isMobileScreen ? '100%' : 'auto' }}
                        >
                          Onayla
                        </Button>
                      </Stack>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Card>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Tahsilat Formu
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {/* 2x2 Grid şeklinde form alanları */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="store-select-label">Mağaza Seçin</InputLabel>
                <Select
                  labelId="store-select-label"
                  value={selectedStore}
                  onChange={(e) => setSelectedStore(e.target.value)}
                  label="Mağaza Seçin"
                >
                  {stores?.map((store) => (
                    <MenuItem key={store.branchID} value={store.branchID}>
                      {store.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Tarih Seç"
                  format="DD/MM/YYYY"
                  sx={{ width: '100%' }}
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Tutar"
                variant="outlined"
                fullWidth
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Makbuz Numarası"
                variant="outlined"
                fullWidth
                value={receiptNumber}
                onChange={(e) => setReceiptNumber(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Çoklu dosya yükleme alanı */}
          <div style={{ width: '100%', marginTop: 16 }}>
            <MultipleFileUpload setFiles={setFiles} files={files} />
          </div>

          {editMode && existingFiles.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Ekli Dosyalar:</Typography>
              {existingFiles.map((file, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                >
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {file}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteExistingFile(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}

          <Dialog open={deleteConfirmOpen} onClose={cancelDelete}>
            <DialogTitle>Onay</DialogTitle>
            <DialogContent>
              <Typography>
                Bu dokümanı silmek istediğinize emin misiniz?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDelete} variant="outlined">
                İptal
              </Button>
              <Button onClick={confirmDelete} variant="contained" color="error">
                Sil
              </Button>
            </DialogActions>
          </Dialog>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Hesaba yatırıldı mı?
            </Typography>
            <RadioGroup
              value={isDeposited}
              onChange={(e) => setIsDeposited(e.target.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Evet hesaba yatırıldı."
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Hayır hesaba yatırılmadı."
              />
            </RadioGroup>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '25px',
            padding: '20px',
          }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={handleCloseDialog}
            sx={{ width: '130px' }}
          >
            {editMode ? 'İptal' : 'İşlemi İptal Et'}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitDialog}
            disabled={submitting}
            sx={{ width: '130px' }}
          >
            {submitting ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : editMode ? (
              'Kaydet'
            ) : (
              'İşlemi Oluştur'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={attachmentModalOpen}
        onClose={handleCloseAttachmentModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Dosyalar
          <IconButton
            aria-label="close"
            onClick={handleCloseAttachmentModal}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedAttachmentFiles && selectedAttachmentFiles.length > 0 ? (
            selectedAttachmentFiles.map((file, index) => (
              <Typography key={index} variant="body1" sx={{ my: 1 }}>
                <a href={file} target="_blank" rel="noopener noreferrer">
                  {file}
                </a>
              </Typography>
            ))
          ) : (
            <Typography variant="body1">Dosya bulunamadı.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAttachmentModal} variant="contained">
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
