import {
    Card,
    Stack,
    Typography,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Services from 'src/services/Services';

export default function EasyMall() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [isMallActive, setMallActive] = useState(false);
    const [isProductsListed, setProductsListed] = useState(false);
    const [isConciergeActive, setConciergeActive] = useState(false);

    const [openMallContract, setOpenMallContract] = useState(false);
    const [openProductsContract, setOpenProductsContract] = useState(false);
    const [openConciergeContract, setOpenConciergeContract] = useState(false);

    const [productLink, setProductLink] = useState('');
    const [linkLoading, setLinkLoading] = useState(false);
    const [linkConfirmed, setLinkConfirmed] = useState(false);

    const [agreementMall, setAgreementMall] = useState('');
    const [agreementProducts, setAgreementProducts] = useState('');
    const [agreementConcierge, setAgreementConcierge] = useState('');

    const handleMallSwitchChange = (event) => {
        if (event.target.checked) {
            setOpenMallContract(true);
        } else {
            setMallActive(false);
        }
    };

    const confirmMallContract = () => {
        setMallActive(true);
        setOpenMallContract(false);
    };

    const cancelMallContract = () => {
        setMallActive(false);
        setOpenMallContract(false);
    };

    const handleProductsSwitchChange = (event) => {
        if (event.target.checked) {
            setOpenProductsContract(true);
        } else {
            resetProductLink();
            setProductsListed(false);
        }
    };

    const resetProductLink = () => {
        setProductLink('');
        setLinkLoading(false);
        setLinkConfirmed(false);
    };

    const cancelProductsContract = () => {
        resetProductLink();
        setProductsListed(false);
        setOpenProductsContract(false);
    };

    const handleProductLinkSubmit = () => {
        if (productLink.trim() === '') return;
        setLinkLoading(true);
        setLinkConfirmed(false);
        setTimeout(() => {
            setLinkLoading(false);
            setLinkConfirmed(true);
        }, 1500);
    };

    const confirmProductsContract = () => {
        if (linkConfirmed) {
            setProductsListed(true);
            setOpenProductsContract(false);
        }
    };

    const handleConciergeSwitchChange = (event) => {
        if (event.target.checked) {
            setOpenConciergeContract(true);
        } else {
            setConciergeActive(false);
        }
    };

    const confirmConciergeContract = () => {
        setConciergeActive(true);
        setOpenConciergeContract(false);
    };

    const cancelConciergeContract = () => {
        setConciergeActive(false);
        setOpenConciergeContract(false);
    };

    useEffect(() => {
        const fetchAgreements = async () => {
            try {
                const mallRes = await Services.GetAgreement(parsedUser.token, 'easy-mall-01');
                setAgreementMall(mallRes.data.result.description);
                const productsRes = await Services.GetAgreement(parsedUser.token, 'easy-mall-02');
                setAgreementProducts(productsRes.data.result.description);
                const conciergeRes = await Services.GetAgreement(parsedUser.token, 'easy-mall-03');
                setAgreementConcierge(conciergeRes.data.result.description);
            } catch (error) {
                console.error("Sözleşmeler çekilirken hata oluştu:", error);
            }
        };

        fetchAgreements();
    }, [parsedUser.token]);

    return (
        <>
            <Helmet>
                <title> Easy Mall | Mall Logistics Panel</title>
            </Helmet>
            <Stack sx={{ paddingInline: '25px' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Easy Mall
                    </Typography>
                </Stack>

                <Card sx={{ padding: '20px', width: '50%' }}>
                    <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography>Easy Mall Sistemini Aktif Et</Typography>
                            <Switch checked={isMallActive} onChange={handleMallSwitchChange} />
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography style={{flexDirection:'row',display:'flex'}}>Easy Mall Sisteminde Ürünlerimi Listele {linkConfirmed && <span style={{flexDirection:'row',display:'flex',marginLeft:'3px',gap:'5px'}}><Typography color={"#54D62C"}> - Link bağlı</Typography> <CheckCircleIcon color="success" /></span> }</Typography>
                            <Switch checked={isProductsListed} onChange={handleProductsSwitchChange} />
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography>Easy Mall Concierge Sistemini Aktif Et</Typography>
                            <Switch checked={isConciergeActive} onChange={handleConciergeSwitchChange} />
                        </Stack>
                    </Stack>
                </Card>

                <Dialog open={openMallContract} onClose={cancelMallContract}>
                    <DialogTitle>Sözleşme - Easy Mall Sistemi Aktif Etme</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            {agreementMall ? <div dangerouslySetInnerHTML={{ __html: agreementMall }} /> : "Sözleşme yükleniyor..."}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelMallContract}>İptal</Button>
                        <Button onClick={confirmMallContract} variant="contained">
                            Onayla
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openProductsContract} onClose={cancelProductsContract}>
                    <DialogTitle>Sözleşme - Ürünlerimin Listelenmesi</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            {agreementProducts ? <div dangerouslySetInnerHTML={{ __html: agreementProducts }} /> : "Sözleşme yükleniyor..."}
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <TextField
                                label="Ürün Linki"
                                fullWidth
                                value={productLink}
                                onChange={(e) => setProductLink(e.target.value)}
                                disabled={linkLoading || linkConfirmed}
                            />
                            <Button
                                variant="outlined"
                                onClick={handleProductLinkSubmit}
                                disabled={linkLoading || productLink.trim() === '' || linkConfirmed}
                            >
                                Bağla
                            </Button>
                            {linkLoading && <CircularProgress size={24} />}
                            {linkConfirmed && <CheckCircleIcon color="success" />}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelProductsContract}>İptal</Button>
                        <Button
                            onClick={confirmProductsContract}
                            variant="contained"
                            disabled={!linkConfirmed}
                        >
                            Onayla
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openConciergeContract} onClose={cancelConciergeContract}>
                    <DialogTitle>Sözleşme - Concierge Sistemi Aktif Etme</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            {agreementConcierge ? <div dangerouslySetInnerHTML={{ __html: agreementConcierge }} /> : "Sözleşme yükleniyor..."}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancelConciergeContract}>İptal</Button>
                        <Button onClick={confirmConciergeContract} variant="contained">
                            Onayla
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </>
    );
}
