import { useState, useEffect, useRef } from 'react';

function useStateWithCallbackLazy(initialState) {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(null);

  // Yeni state atarken callback'i de saklıyoruz
  const setStateCallback = (newState, callback) => {
    callbackRef.current = callback;
    setState(newState);
  };

  useEffect(() => {
    // State güncellemesi tamamlandığında callback varsa çalıştırıyoruz
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null; // Callback'i sıfırlıyoruz
    }
  }, [state]);

  return [state, setStateCallback];
}

export default useStateWithCallbackLazy;
