import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Grid,
  Typography,
  TextField,
  Modal,
  TablePagination,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import ClearButton from 'src/components/shared/ClearButton';
import InfoIcon from '@mui/icons-material/Info';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import Services from 'src/services/Services';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify';
import { toast } from 'react-toastify';
import { tokenService } from 'src/services';
import ReactApexChart from 'react-apexcharts';
import { useLocalization } from 'src/context/LocalizationContext';
import { styled } from '@mui/material/styles';
import api from 'src/services/BaseService';

// ----------------------------------------------------------------------

const storeRanges = {
  Otel: 152,
  'Easy Point': 26,
  'Emanet Dolabı': 30,
  'Eve Teslim': 8,
};

const dummyData = [
  {
    id: '672',
    orderID: '83794027282717857771',
    branchID: '69735789133633497008',
    branchTitle: 'İstinyePark - Şube 2',
    locationID: '89419821266684517474',
    createdDateTime: '1727874175',
    status: '3',
    mallID: '07092833969355685660',
    additionalNote: 'Test Not',
    deliveryTypeInfo: {
      deliveryType: 'points',
      type: 'location',
      id: '77656762656796653138',
      text: 'Test Hotel',
      additionalNote: 'test',
      price: 0,
      currency: 'TRY',
    },
    name: 'El***',
    lastname: 'Ay***',
    phone: '+905********',
  },
  {
    id: '609',
    orderID: '91250988472140015836',
    branchID: '62371752792025709799',
    branchTitle: 'İstinyePark - Şube 2',
    locationID: '89419821266684517474',
    createdDateTime: '1725368575',
    status: '1',
    mallID: '07092833969355685660',
    additionalNote: 'Test Not',
    deliveryTypeInfo: {
      deliveryType: 'points',
      type: 'location',
      id: '79986434903677964238',
      text: 'Test Hotel',
      additionalNote: 'test',
      price: 0,
      currency: 'TRY',
    },
    name: 'Me***',
    lastname: 'Öz***',
    phone: '+905********',
  },
  {
    id: '637',
    orderID: '56960058563316519619',
    branchID: '63345586323660896143',
    branchTitle: 'Zorlu Center - Şube 1',
    locationID: '89419821266684517474',
    createdDateTime: '1713790975',
    status: '2',
    mallID: '07092833969355685660',
    additionalNote: 'Test Not',
    deliveryTypeInfo: {
      deliveryType: 'points',
      type: 'location',
      id: '75118593895374174563',
      text: 'Test Hotel',
      additionalNote: 'test',
      price: 0,
      currency: 'TRY',
    },
    name: 'Ay***',
    lastname: 'Ay***',
    phone: '+905********',
  },
  {
    id: '669',
    orderID: '31386360281706420804',
    branchID: '64391344701351789879',
    branchTitle: 'İstinyePark - Şube 2',
    locationID: '89419821266684517474',
    createdDateTime: '1710162175',
    status: '1',
    mallID: '07092833969355685660',
    additionalNote: 'Test Not',
    deliveryTypeInfo: {
      deliveryType: 'points',
      type: 'location',
      id: '71848072598945257890',
      text: 'Test Hotel',
      additionalNote: 'test',
      price: 0,
      currency: 'TRY',
    },
    name: 'Al***',
    lastname: 'Çe***',
    phone: '+905********',
  },
  {
    id: '688',
    orderID: '87651387298244332266',
    branchID: '69488408877419012694',
    branchTitle: 'Cevahir AVM - Şube 5',
    locationID: '89419821266684517474',
    createdDateTime: '1720098175',
    status: '4',
    mallID: '07092833969355685660',
    additionalNote: 'Test Not',
    deliveryTypeInfo: {
      deliveryType: 'points',
      type: 'location',
      id: '75332388646509295904',
      text: 'Test Hotel',
      additionalNote: 'test',
      price: 0,
      currency: 'TRY',
    },
    name: 'Fa***',
    lastname: 'Çe***',
    phone: '+905********',
  },
];

const OPERATIONS_TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'name', label: 'Ad Soyad', alignRight: false },
  { id: 'phone', label: 'Telefon', alignRight: false },
  { id: 'date', label: 'Tarih', alignRight: false },
  { id: 'state', label: 'Durum', alignRight: false },
];

export default function HandsfreePageFake() {
  const StyledIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
  }));
  const { language } = useLocalization();
  const [locationID, setLocationID] = useState('');
  const [token, setToken] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedTab, setSelectedTab] = useState('talepleri');
  const [countModal, setCountModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [count, setCount] = useState('');
  const [selectedOrderID, setSelectedOrderID] = useState('');
  const [requests, setRequests] = useState([]);
  const [operations, setOperations] = useState([]);
  const [completeds, setCompleteds] = useState([]);
  const [success, setSuccess] = useState(false);
  const [rejectedSuccess, setRejectedSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showType, setShowType] = useState('all');
  const [onlyWaitingDatas, setOnlyWaitingDatas] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxChecked2, setIsCheckboxChecked2] = useState(false);
  const [note, setNote] = useState('');
  const [userNote, setUserNote] = useState('');
  const [isHotel, setIsHotel] = useState(false);
  const [showPopUpError, setShowPopUpError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    phone: '',
    inventory: 0,
    email: '',
    note: '',
    deliveryType: '',
  });
  const [deliveryPassword, setDeliveryPassword] = useState('');
  const [successCreated, setSuccessCreated] = useState(false);
  const [storeOrderNumbers, setStoreOrderNumbers] = useState();
  const [notForStore, setNotForStore] = useState('');
  const datas = JSON.parse(localStorage.getItem('user'));
  const [deliveryTypes, setDeliveryTypes] = useState([]);

  const [predefinedTypes, setPredefinedTypes] = useState([]);
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [selectedPredefined, setSelectedPredefined] = useState('');
  const [hotelRoom, setHotelRoom] = useState('');
  const [pointsTypes, setPointsTypes] = useState([]);
  const [selectedPointType, setSelectedPointType] = useState('');
  const [selectedSubValue, setSelectedSubValue] = useState('');
  const [townList, setTownList] = useState([]);
  const [selectedTown, setSelectedTown] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [lockBoxList, setLockBoxList] = useState([]);
  const [selectedLockBox, setSelectedLockBox] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [addressDescription, setAddressDescription] = useState('');
  const [mall, setMall] = useState();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [filteredData, setFilteredData] = useState([]);

  function extractUniqueTypes(dataArray) {
    const types = new Set(); // Tekrar eden typeları önlemek için Set kullanıyoruz

    dataArray?.forEach((item) => {
      if (item.type) {
        types.add(item.type); // type varsa Set'e ekle
      }
    });

    return Array.from(types); // Set'i array'e dönüştürüyoruz
  }

  const extractTowns = (obj) => {
    if (obj) {
      return Object.keys(obj).map((town) => town);
    }
  };

  function checkDataAvailability(data) {
    const result = {};

    let deliveryTypesArr = [];
    let predefinedTypesArr = extractUniqueTypes(data?.predefined);
    let pointsTypesArr = extractUniqueTypes(data?.points);
    let townListArr = extractTowns(data?.localDelivery);
    let lockBoxListArr = data.postbox;
    // Genel kontrol fonksiyonu: değer var mı ve null, false veya boş değil mi?
    const isValid = (value) =>
      value !== null &&
      value !== false &&
      value !== undefined &&
      (Array.isArray(value)
        ? value?.length > 0
        : Object.keys(value || {})?.length > 0);

    // Points kontrolü
    if (isValid(data.points)) {
      result.points = 'Points mevcut ve geçerli.';
      deliveryTypesArr.push({ type: 'points', title: 'Easy Point' });
    }

    // Predefined kontrolü
    if (isValid(data.predefined) || isValid(data.localDelivery)) {
      result.predefined = 'Predefined mevcut ve geçerli.';
      deliveryTypesArr.push({
        type: 'address',
        title: 'Adrese Teslim',
      });
    }

    // Postbox kontrolü
    if (isValid(data.postbox)) {
      result.postbox = 'Postbox mevcut ve geçerli.';
      deliveryTypesArr.push({
        type: 'postbox',
        title: 'Dolaba Teslim',
      });
    }

    // LocalDelivery kontrolü
    if (isValid(data.localDelivery)) {
      result.localDelivery = 'LocalDelivery mevcut ve geçerli.';
    }

    if (townListArr?.length === 1) {
      setSelectedTown(townListArr[0]);
    }

    setLockBoxList(lockBoxListArr);
    setTownList(townListArr);
    setPointsTypes(pointsTypesArr);
    setPredefinedTypes(predefinedTypesArr);
    setDeliveryTypes(deliveryTypesArr);
  }

  function exportToExcel() {
    const exportData = dummyData.map((x, index) => {
      // Tarihi formatlayalım (epoch zamanını kullanarak)
      const dateFormatted = moment
        .unix(x.createdDateTime)
        .format('DD/MM/YYYY HH:mm');

      // Durum bilgisini string olarak belirleyelim
      let statusText = '';
      switch (x.status) {
        case '1':
          statusText = 'Tamamlandı';
          break;
        case '2':
          statusText = 'İptal Edildi';
          break;
        case '3':
          statusText = 'Mağazada Bekliyor';
          break;
        case '4':
          statusText = 'Porter Taşımasında';
          break;
        case '5':
          statusText = 'Teslim Noktasında';
          break;
        case '6':
          statusText = 'Müşteri Onayı Bekliyor';
          break;
        default:
          statusText = 'Bilinmiyor';
          break;
      }

      return {
        No: index + 1,
        'Ad Soyad': `${x.name} ${x.lastname}`,
        Telefon: x.phone,
        Tarih: dateFormatted,
        Durum: statusText,
      };
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: exportData,
        header: {
          No: 'number',
          'Ad Soyad': 'string',
          Telefon: 'string',
          Tarih: 'string',
          Durum: 'string',
        },
        sheetTitle: 'Hands Free İşlemleri',
      })
      .then((response) => {
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  const clearListOptions = () => {
    // setSelectedLocation('')
    setStartDate(null);
    setEndDate(null);
    setFilteredData(dummyData);
  };

  const createHandsfreeByStore = () => {
    // userID de gönderilecek
    const createdBy = tokenService.getUser()?.userID;
    setLoading(true);
    if (
      formData.name === '' ||
      formData.lastname === '' ||
      (formData.phone === '' && formData.email === '') ||
      formData.inventory <= 0 ||
      formData.inventory === null ||
      formData.inventory === undefined ||
      formData.inventory === NaN
    ) {
      toast.error('Zorunlu alanları doldurunuz.');
      setLoading(false);
    } else {
      let deliveryTypeInfo = {};

      if (selectedSubValue) {
        deliveryTypeInfo = {
          deliveryType: formData.deliveryType,
          type: selectedSubValue.type,
          id: selectedSubValue.id,
          text: selectedSubValue.title,
          additionalNote: addressDescription,
          price: selectedSubValue.price,
          currency: selectedSubValue.currency,
        };
      } else if (selectedPredefined) {
        deliveryTypeInfo = {
          deliveryType: formData.deliveryType,
          type: 'predefined',
          text: selectedPredefined.title,
          additionalNote: '(Oda No: ' + hotelRoom + ') ' + addressDescription,
          price: selectedPredefined.price,
          currency: selectedPredefined.currency,
        };
      } else if (selectedTown) {
        deliveryTypeInfo = {
          deliveryType: formData.deliveryType,
          type: 'custom',
          text: selectedTown + ' ' + selectedDistrict + ' ' + fullAddress,
          additionalNote: addressDescription,
          price: mall.deliveryTypes.localDelivery[selectedTown].price,
          currency: mall.deliveryTypes.localDelivery[selectedTown].currency,
        };
      } else if (selectedLockBox) {
        deliveryTypeInfo = {
          deliveryType: formData.deliveryType,
          locationCode: selectedLockBox.locationCode,
          text: selectedLockBox.title,
          additionalNote: addressDescription,
          price: selectedLockBox.price,
          currency: selectedLockBox.currency,
          id: selectedLockBox.id,
        };
      }

      const additionalNote = `Teslim alacak kişi: ${formData.name} ${formData.lastname} Not: ${formData.note}`;
      console.log('test: ', {
        locationID,
        formData,
        additionalNote,
        createdBy,
        deliveryTypeInfo,
      });
      setNotForStore(additionalNote);
      Services.CreateHandsfreeByStore(
        token,
        locationID,
        formData,
        additionalNote,
        createdBy,
        deliveryTypeInfo
      )
        .then((res) => {
          console.log('create handsfree: ', res.data);
          if (res.data.status) {
            toast.success(
              `${
                tokenService.getUser().authLocations.branch[0].mallID ===
                '98569271082166328155'
                  ? 'Bags-Free'
                  : 'Hands Free'
              } Gönderisi başarıyla oluşturuldu.`
            );
            setDeliveryPassword(res.data.result.deliveryPassword);
            setStoreOrderNumbers(res.data.result.orderNumbers);
            setCreateModal(false);
            setSuccessCreated(true);
          } else {
            toast.error(
              `${
                tokenService.getUser().authLocations.branch[0].mallID ===
                '98569271082166328155'
                  ? 'Bags-Free'
                  : 'Hands Free'
              } gönderisi oluşturulurken hata oluştu`
            );
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error(
            `${
              tokenService.getUser().authLocations.branch[0].mallID ===
              '98569271082166328155'
                ? 'Bags-Free'
                : 'Hands Free'
            } gönderisi oluşturulurken hata oluştu`
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleSuccess = (type) => {
    if (type === 'confirm') {
      setSuccess(true);
    } else {
      setRejectedSuccess(true);
    }
    setLoading(false);
  };

  const handleFailed = () => {
    setFailed(true);
    setLoading(false);
  };

  const handleCloseCountModal = () => {
    if (loading) {
      return;
    } else {
      setCountModal(false);
      setSelectedOrderID('');
      setCount('');
      setNote('');
    }
  };
  const handleCloseCreateModal = () => {
    if (loading) {
      return;
    } else {
      setCreateModal(false);
      setFormData({
        name: '',
        lastname: '',
        phone: '',
        email: '',
        inventory: 0,
        note: '',
      });
    }
  };

  const confirmRequest = () => {
    if (count === null || +count <= 0) {
      toast.error('Lütfen paket sayısını doğru giriniz.');
    } else {
      // console.log(selectedOrderID, count);
      setLoading(true);
      Services.GetHandsFreeConfirm(token, selectedOrderID, +count)
        .then((res) => {
          console.log('confirm response: ', res.data);
          setOrderNumbers(res.data.result);
          handleSuccess('confirm');
          setCountModal(false);
          setSelectedOrderID('');
          setCount('');
        })
        .catch((err) => {
          // console.log(err);
          // console.log('Bir hata oluştu');
          handleFailed();
        });
    }
  };

  const rejectRequest = () => {
    // console.log(selectedOrderID, token);
    Services.GetHandsFreeReject(token, selectedOrderID)
      .then((res) => {
        // console.log(res.data);
        handleSuccess('reject');
        setRejectModal(false);
        setSelectedOrderID('');
      })
      .catch((err) => {
        // console.log(err);
        // console.log('Bir hata oluştu');
        handleFailed();
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const processedValue = name === 'inventory' ? parseInt(value, 10) : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));

    if (name === 'deliveryType') {
      setSelectedAddressType('');
      setSelectedPredefined('');
      setSelectedPointType('');
      setSelectedSubValue('');
      setSelectedTown('');
      setSelectedLockBox('');
      setSelectedDistrict('');
      setFullAddress('');
      setAddressDescription('');
    }
  };

  const handlePointTypeChange = (event) => {
    setSelectedPointType(event.target.value);
    setSelectedSubValue('');
  };

  const handlePredefinedTypeChange = (event) => {
    setSelectedAddressType(event.target.value);
    setSelectedPredefined('');
    setSelectedTown('');
    setHotelRoom('');
    setSelectedDistrict('');
    setFullAddress('');
    setAddressDescription('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const isButtonDisabled = () => {
    if (!formData.deliveryType) return true;

    if (formData.deliveryType === 'points') {
      return !selectedPointType || !selectedSubValue;
    }

    if (formData.deliveryType === 'address') {
      if (!selectedAddressType) return true;

      if (selectedAddressType === 'address') {
        return !selectedTown || !selectedDistrict || !fullAddress;
      }
      if (selectedAddressType === 'predefined') {
        return (
          !selectedPredefined ||
          (selectedPredefined.type === 'hotel' && !hotelRoom)
        );
      }
    }

    if (formData.deliveryType === 'postbox') {
      return !selectedLockBox;
    }

    return false;
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - req?.length) : 0;

  const findLocation = (handsfreeToken, branch, token) => {
    // console.log(token, branch);
    Services.GetLocation(token, branch)
      .then((res) => {
        // console.log('location response: ', res.data);
        setLocationID(res?.data?.result[0]?.locationID);
        Services.GetHandsFreeOrders(
          handsfreeToken,
          res.data.result?.map((x) => x.locationID)
        )
          .then((response) => {
            console.log('handsfreeOrders: ', response.data);
            const allRequests = response.data.result;
            // Tamamı
            const sortedRequests = allRequests?.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            );
            setAllRequests(sortedRequests);
            setRequests(sortedRequests);
            // Sadece Bekleyenler
            const onlyWaitingDatas = allRequests?.filter(
              (item) => item.status === '3'
            );
            const sortedWaitingDatas = onlyWaitingDatas?.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            );
            setOnlyWaitingDatas(sortedWaitingDatas);
          })
          .catch((error) => {
            // console.log('Orders error: ', error);
            setRequests([]);
          });
      })
      .catch((err) => {
        // console.log('locations error: ', err);
      });
  };

  const fetchDatas = () => {
    setFilteredData(dummyData);
    // Services.GetToken()
    //   .then((response) => {
    //     setToken(response.data.result.token);
    //     findLocation(response.data.result.token, datas.branchID, datas.token);
    //     Services.GetMallsForHandsfree(response.data.result.token)
    //       .then((res) => {
    //         const mallID = datas.authLocations.branch[0].mallID;
    //         const matchedMall = res.data.result.find(
    //           (mall) => mall.id === mallID
    //         );
    //         setMall(matchedMall);
    //         if (matchedMall) {
    //           checkDataAvailability(matchedMall.deliveryTypes);
    //         } else {
    //           console.log('Eşleşen mall bulunamadı.');
    //         }
    //       })
    //       .catch((err) => console.log(err));
    //     Services.GetHandsFreePosts(
    //       response.data.result.token,
    //       datas.branchID,
    //       [1, 2, 3, 4, 5, 6]
    //     )
    //       .then((res) => {
    //         const sortedOperations = res.data.result.sort(
    //           (a, b) => b.createdDateTime - a.createdDateTime
    //         );
    //         console.log('sortedOperations: ', sortedOperations);
    //         setOperations(sortedOperations);
    //       })
    //       .catch((err) => {
    //         // console.log(err);
    //         setOperations([]);
    //       });
    //     Services.GetHandsFreePosts(response.data.result.token, datas.branchID, [
    //       1,
    //     ])
    //       .then((res) => {
    //         console.log('GetHandsFreePosts (1):', res.data);
    //         const sortedPosts = res.data.result.sort(
    //           (a, b) => b.createdDateTime - a.createdDateTime
    //         );
    //         setCompleteds(sortedPosts);
    //       })
    //       .catch((err) => {
    //         // console.log(err);
    //         setCompleteds([]);
    //       });
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
  };

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       fetchDatas();
  //     }, 30000);
  //     return () => clearInterval(intervalId);
  //   }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      <Helmet>
        <title> {'Hands Free'} | Minimal UI </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            {`Hands Free
               İşlemleri`}
          </Typography>
        </Stack>

        <Box
          style={{
            width: '100%',
            border: 'solid 1px #F7A937',
            padding: 15,
            borderRadius: 5,
            backgroundColor: '#FFF3CD',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 10,
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              alingItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InfoIcon
              style={{
                color: '#F7A937',
                fontSize: 24,
                alignSelf: 'center',
                marginRight: 5,
              }}
            />
            <Typography style={{ color: '#29292b' }}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt
              odit recusandae reprehenderit! Reiciendis iusto saepe quas velit
              exercitationem sed id alias amet ipsum? Itaque earum numquam nam
              tenetur. Modi, qui. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Assumenda voluptates sint, quas labore similique
              omnis fugiat aliquam minus nobis saepe, sunt architecto animi
              doloremque ducimus quis nemo, accusantium beatae voluptatem!
            </Typography>
          </Box>

          <Button
            style={{
              height: 50,
              alignSelf: 'flex-start',
              minWidth: 120,
              marginLeft: 28,
              backgroundColor: '#3150A0',
              color: '#fff',
              fontWeight: 'bold',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
            }}
            variant="contained"
          >
            Başvur
          </Button>
        </Box>

        <Grid container spacing={2} mb={3}>
          {/* Toplam Kullanım */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '250px' }}>
              <Card
                sx={{
                  height: '250px',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  paddingInline: '32px',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ flexDirection: 'column', display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#1F2A37',
                      fontFamily: 'Inter',
                    }}
                  >
                    Aylık İşlem Adedi
                  </Typography>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#9CA3AF',
                      fontFamily: 'Inter',
                    }}
                  >
                    {language.checkroom.titles.total} İşlem:{' '}
                    <span style={{ color: '#6B7280' }}>216</span>
                  </span>

                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#9CA3AF',
                      fontFamily: 'Inter',
                    }}
                  >
                    {language.checkroom.titles.average} İşlem:{' '}
                    <span style={{ color: '#6B7280' }}>7.2</span>
                  </span>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#9CA3AF',
                      fontFamily: 'Inter',
                    }}
                  >
                    Toplam Ciro :{' '}
                    <span style={{ color: '#6B7280' }}>43200₺</span>
                  </span>
                </div>

                <StyledIcon
                  sx={{
                    color: 'white',
                    backgroundImage: (theme) =>
                      `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`,
                  }}
                >
                  <Iconify icon={'charm:chart-bar'} width={24} height={24} />
                </StyledIcon>
              </Card>
            </Card>
          </Grid>

          {/* Vestiyer Kullanım Dağılımı - Pie Chart */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '250px', padding: '20px' }}>
              <Typography variant="h6">Teslimat Tercihi</Typography>
              <ReactApexChart
                options={{
                  labels: Object.keys(storeRanges),
                  chart: { type: 'pie' },
                  colors: [
                    '#3150A0',
                    '#F7A937',
                    '#9acc62',
                    '#822848',
                    '#65a6a0',
                  ],
                }}
                series={Object.values(storeRanges)}
                type="pie"
                height={200}
              />
            </Card>
          </Grid>

          {/* Günlük Kullanım Trendi - Bar Chart */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '250px', padding: '20px' }}>
              <Typography variant="h6">
                2025 Yılı Handsfree İşlemleri
              </Typography>
              <ReactApexChart
                options={{
                  colors: ['#F7A937'],
                  xaxis: {
                    categories: ['Ocak', 'Şubat', 'Mart'],
                  },
                  chart: {
                    type: 'bar',
                    toolbar: {
                      show: false,
                    },
                  },
                }}
                series={[
                  {
                    name: 'Kullanım Sayısı',
                    data: [15, 26, 0],
                  },
                ]}
                type="bar"
                height={200}
              />
            </Card>
          </Grid>
        </Grid>

        <Card style={{ padding: 25, marginBottom: 20 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={language.commons.labels.start_date}
                  value={moment('2025-02-11')}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    setEndDate(null);
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label={language.commons.labels.end_date}
                  value={moment('2025-02-11')}
                  // minDate={startDate}
                  // disabled={!startDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>

              <Button
                sx={{
                  height: '40px',
                  fontWeight: 400,
                  fontSize: 14,
                  fontFamily: 'Inter',
                  letterSpacing: 0.4,
                  alignSelf: 'center',
                }}
                disabled={!startDate || !endDate}
                variant="contained"
                // onClick={() => getDropboxListByLocations()}
              >
                Listele
              </Button>
              {/* <ClearButton
                sx={{ alignSelf: 'center' }}
                onClick={clearListOptions}
              /> */}
            </div>

            <div style={{ display: 'flex' }}>
              {' '}
              <ExportToExcelButton
                onClick={exportToExcel}
                setShowPopUpError={setShowPopUpError}
                showPopUpError={showPopUpError}
              />
            </div>
          </div>
        </Card>

        <Card
          sx={{
            backgroundColor: 'white',
            paddingBottom: '30px',
          }}
        >
          <Scrollbar>
            <Box sx={{ width: '100%', paddingInline: '30px' }}>
              {/* Render content based on the selected tab */}

              {true && (
                <>
                  {filteredData?.length > 0 ? (
                    <div style={{ marginTop: 50 }}>
                      <Table>
                        <UserListHead
                          headLabel={OPERATIONS_TABLE_HEAD}
                          rowCount={operations?.length}
                          onRequestSort={() => null}
                        />
                        <TableBody>
                          {dummyData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const {
                                orderNumber,
                                name,
                                lastname,
                                phone,
                                createdDateTime,
                                status,
                              } = row;

                              return (
                                <TableRow hover key={index} tabIndex={-1}>
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>

                                  <TableCell align="left">
                                    {' '}
                                    {name + ' ' + lastname}
                                  </TableCell>
                                  <TableCell align="left">{phone}</TableCell>
                                  <TableCell align="left">
                                    {' '}
                                    {moment
                                      .unix(createdDateTime)
                                      .format('DD/MM/YYYY HH:mm')}
                                  </TableCell>
                                  <TableCell align="left">
                                    {status === '1'
                                      ? 'Tamamlandı'
                                      : status === '2'
                                      ? 'İptal Edildi'
                                      : status === '3'
                                      ? 'Mağazada Bekliyor'
                                      : status === '4'
                                      ? 'Porter Taşımasında'
                                      : status === '5'
                                      ? 'Teslim Noktasında'
                                      : status === '6'
                                      ? 'Müşteri Onayı Bekliyor'
                                      : 'Bilinmiyor'}{' '}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={dummyData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    <Typography mt={5} color={'gray'}>
                      {tokenService?.getUser()?.authLocations?.branch
                        ? tokenService?.getUser()?.authLocations?.branch[0]
                            ?.mallID === '98569271082166328155'
                          ? 'Bags-Free'
                          : 'Hands Free'
                        : 'Hands Free'}{' '}
                      İşlemleri Bulunmamaktadır
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Scrollbar>
        </Card>
        <Modal
          open={countModal}
          onClose={() => {
            handleCloseCountModal();
            // setCountModal(false);
            // setSelectedOrderID('');
            // setCount('');
            // setNote('');
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Paket Sayısı Giriniz</Typography>
            <TextField
              sx={{ marginBlock: '30px' }}
              fullWidth
              label="Paket Sayısı"
              variant="outlined"
              type="number"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
            <Button
              onClick={() => {
                confirmRequest();
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Tamamla'
              )}
            </Button>
          </Box>
        </Modal>

        <Modal
          open={createModal}
          onClose={() => {
            if (loading) return;
            else handleCloseCreateModal();
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <TextField
              sx={{ marginBlock: '10px' }}
              fullWidth
              label="Ad*"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <TextField
              sx={{ marginBlock: '10px' }}
              fullWidth
              label="Soyad*"
              variant="outlined"
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
            />
            <TextField
              sx={{ marginBlock: '10px' }}
              fullWidth
              label="Telefon*"
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            <TextField
              sx={{ marginBlock: '10px' }}
              fullWidth
              label="E-posta*"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <TextField
              sx={{ marginBlock: '10px' }}
              fullWidth
              label="Paket Adedi*"
              variant="outlined"
              name="inventory"
              type="number"
              value={formData.inventory}
              onChange={handleInputChange}
            />
            <FormControl fullWidth>
              <InputLabel
                style={{ color: '#C1C6C9', fontSize: 15 }}
                id="demo-simple-select-label"
              >
                Teslimat Tipi*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={'Teslimat Tipi*'}
                name="deliveryType"
                value={formData.deliveryType}
                onChange={handleInputChange}
                sx={{
                  marginBottom: 2,
                  backgroundColor: '#ffffff',
                  borderColor: '#C1C6C9',
                  textAlign: 'left',
                }}
              >
                {mall &&
                  deliveryTypes.map((type) => (
                    <MenuItem key={type.type} value={type.type}>
                      {type.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {formData.deliveryType === 'points' && (
              <>
                <FormControl fullWidth>
                  <InputLabel
                    style={{ color: '#C1C6C9', fontSize: 15 }}
                    id="demo-simple-select-label"
                  >
                    Nokta Tipi*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={'Nokta Tipi*'}
                    name="selectedPointType"
                    value={selectedPointType}
                    onChange={handlePointTypeChange}
                    sx={{
                      marginBottom: 2,
                      backgroundColor: '#ffffff',
                      borderColor: '#C1C6C9',
                      textAlign: 'left',
                    }}
                  >
                    {mall &&
                      pointsTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type === 'easypoint' ? 'Easy Point' : 'Lokasyon'}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* İkinci Select Bileşeni */}
                {selectedPointType && (
                  <FormControl fullWidth>
                    <InputLabel
                      style={{ color: '#C1C6C9', fontSize: 15 }}
                      id="second-select-label"
                    >
                      {selectedPointType === 'easypoint'
                        ? 'Easy Point'
                        : 'Lokasyon'}
                      *
                    </InputLabel>
                    <Select
                      labelId="second-select-label"
                      id="second-select"
                      label={
                        selectedPointType === 'easypoint'
                          ? 'Easy Point'
                          : 'Lokasyon'
                      }
                      value={
                        selectedSubValue ? JSON.stringify(selectedSubValue) : ''
                      }
                      onChange={(e) =>
                        setSelectedSubValue(JSON.parse(e.target.value))
                      }
                      sx={{
                        marginBottom: 2,
                        backgroundColor: '#ffffff',
                        borderColor: '#C1C6C9',
                        textAlign: 'left',
                      }}
                    >
                      {mall.deliveryTypes.points
                        .filter((point) => point.type === selectedPointType)
                        .map((point) => (
                          <MenuItem
                            key={point.type}
                            value={JSON.stringify(point)}
                          >
                            {point.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
            {formData.deliveryType === 'address' && (
              <>
                <FormControl fullWidth>
                  <InputLabel
                    style={{ color: '#C1C6C9', fontSize: 15 }}
                    id="demo-simple-select-label"
                  >
                    Adres Tipi*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={'Adres Tipi*'}
                    name="selectedAddressType"
                    value={selectedAddressType}
                    onChange={handlePredefinedTypeChange}
                    sx={{
                      marginBottom: 2,
                      backgroundColor: '#ffffff',
                      borderColor: '#C1C6C9',
                      textAlign: 'left',
                    }}
                  >
                    {mall && predefinedTypes?.length > 0 && (
                      <MenuItem value={'predefined'}>
                        Ön Tanımlı Adres Seçiniz
                      </MenuItem>
                    )}
                    {mall && <MenuItem value={'address'}>Adres Gir</MenuItem>}
                  </Select>
                </FormControl>

                {selectedAddressType === 'address' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                        display: 'flex',
                        gap: '15px',
                      }}
                    >
                      <TextField
                        id="outlined-basic-4"
                        label={'Şehir'}
                        variant="outlined"
                        fullWidth
                        name="city"
                        value={mall.city}
                        disabled
                        InputLabelProps={{
                          style: { color: '#C1C6C9', fontSize: 15 },
                        }}
                        sx={{
                          marginBottom: 2,
                          backgroundColor: '#ffffff',
                          borderColor: '#C1C6C9',
                        }}
                      />

                      <FormControl fullWidth>
                        <InputLabel
                          style={{ color: '#C1C6C9', fontSize: 15 }}
                          id="demo-simple-select-label"
                        >
                          İlçe*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={'İlçe*'}
                          name="selectedAddressType"
                          value={selectedTown}
                          disabled={townList?.length === 1}
                          onChange={handleChangeTown}
                          sx={{
                            marginBottom: 2,
                            backgroundColor: '#ffffff',
                            borderColor: '#C1C6C9',
                            textAlign: 'left',
                          }}
                        >
                          {mall &&
                            townList.map((town) => (
                              <MenuItem key={town} value={town}>
                                {town}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>

                    <FormControl fullWidth>
                      <InputLabel
                        style={{ color: '#C1C6C9', fontSize: 15 }}
                        id="demo-simple-select-label"
                      >
                        Mahalle*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={'Mahalle*'}
                        name="selectedTown"
                        value={selectedDistrict}
                        disabled={!selectedTown}
                        onChange={(e) => setSelectedDistrict(e.target.value)}
                        sx={{
                          marginBottom: 2,
                          backgroundColor: '#ffffff',
                          borderColor: '#C1C6C9',
                          textAlign: 'left',
                        }}
                      >
                        {mall &&
                          districtList?.map((district) => (
                            <MenuItem key={district} value={district}>
                              {district}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            )}
            {selectedAddressType === 'predefined' && (
              <>
                <FormControl fullWidth>
                  <InputLabel
                    style={{ color: '#C1C6C9', fontSize: 15 }}
                    id="demo-simple-select-label"
                  >
                    Ön Tanımlı Adres Seçiniz*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={'Ön Tanımlı Adres Seçiniz*'}
                    name="selectedPredefined"
                    value={selectedPredefined}
                    onChange={(e) => setSelectedPredefined(e.target.value)}
                    sx={{
                      marginBottom: 2,
                      backgroundColor: '#ffffff',
                      borderColor: '#C1C6C9',
                      textAlign: 'left',
                    }}
                  >
                    {mall &&
                      mall.deliveryTypes.predefined.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {selectedPredefined.type === 'hotel' && (
                  <TextField
                    id="outlined-basic-4"
                    label={`Oda No*`}
                    variant="outlined"
                    fullWidth
                    name="hotelRoom"
                    value={hotelRoom}
                    onChange={(e) => setHotelRoom(e.target.value)}
                    InputLabelProps={{
                      style: { color: '#C1C6C9', fontSize: 15 },
                    }}
                    sx={{
                      marginBottom: 5,
                      backgroundColor: '#ffffff',
                      borderColor: '#C1C6C9',
                    }}
                  />
                )}
              </>
            )}
            {formData.deliveryType === 'postbox' && (
              <>
                <FormControl fullWidth>
                  <InputLabel
                    style={{ color: '#C1C6C9', fontSize: 15 }}
                    id="demo-simple-select-label"
                  >
                    Dolap Lokasyonu*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={'Dolap Lokasyonu*'}
                    name="selectedLockBox"
                    value={selectedLockBox}
                    onChange={(e) => setSelectedLockBox(e.target.value)}
                    sx={{
                      marginBottom: 2,
                      backgroundColor: '#ffffff',
                      borderColor: '#C1C6C9',
                      textAlign: 'left',
                    }}
                  >
                    {mall &&
                      lockBoxList.map((lockBox) => (
                        <MenuItem key={lockBox.locationCode} value={lockBox}>
                          {lockBox.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
            {formData.deliveryType && (
              <>
                {selectedAddressType === 'address' && (
                  <TextField
                    id="outlined-multiline-1"
                    label={'Açık Adres Bilginizi Girin...'}
                    multiline
                    fullWidth
                    rows={4}
                    value={fullAddress}
                    onChange={(e) => setFullAddress(e.target.value)}
                    InputProps={{
                      style: {
                        height: 100,
                      },
                    }}
                    sx={{
                      marginBottom: 2,
                      backgroundColor: '#ffffff',
                      borderColor: '#C1C6C9',
                    }}
                  />
                )}

                <TextField
                  id="outlined-multiline-2"
                  label={
                    selectedAddressType === 'address'
                      ? 'Adres Notu Giriniz'
                      : 'Not Giriniz'
                  }
                  multiline
                  fullWidth
                  value={addressDescription}
                  onChange={(e) => setAddressDescription(e.target.value)}
                  rows={4}
                  InputProps={{
                    style: {
                      height: 100,
                    },
                  }}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: '#ffffff',
                    borderColor: '#C1C6C9',
                  }}
                />
              </>
            )}

            <Button
              disabled={loading || isButtonDisabled()}
              onClick={() => createHandsfreeByStore()}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Tamamla'
              )}
            </Button>
          </Box>
        </Modal>

        <Modal
          open={rejectModal}
          onClose={() => {
            setRejectModal(false);
            setSelectedOrderID('');
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">
              Müşterinin talebini reddetmek istediğinize emin misiniz?
            </Typography>

            <Button
              onClick={() => {
                rejectRequest();
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Evet'
              )}
            </Button>
          </Box>
        </Modal>

        <Dialog open={success}>
          <DialogTitle color={'green'}>Başarılı</DialogTitle>
          <DialogContent>
            <Typography>İşleminiz gerçekleştirildi.</Typography>
            <Stack direction="column" spacing={2} mt={2}>
              {orderNumbers?.map((orderNumber, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Typography>
                    {index + 1}) {orderNumber}
                  </Typography>
                </Box>
              ))}
              {note && (
                <Typography>
                  Alıcı: {note.split(' ')[0]} {note.split(' ')[1][0]}.
                </Typography>
              )}

              {userNote && userNote?.length > 0 && (
                <Typography>Müşteri Notu: {userNote}</Typography>
              )}

              <Box display="flex" alignItems="center" sx={{ width: 350 }}>
                <Checkbox
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                {!isHotel ? (
                  <Typography>
                    Gönderi kodlarını ilgili paketlerin üzerine yazdım.
                  </Typography>
                ) : (
                  <Typography>
                    Gönderi kodlarını, müşteri adını ve oda numarasını ilgili
                    paketlerin üzerine yazdım.
                  </Typography>
                )}
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!isCheckboxChecked}
              onClick={() => {
                setSuccess(false);
                window.location.reload(false);
              }}
              color="primary"
            >
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={successCreated}>
          <DialogTitle color={'green'}>Başarılı</DialogTitle>
          <DialogContent>
            <Typography>İşleminiz gerçekleştirildi.</Typography>
            <Stack direction="column" spacing={2} mt={2}>
              {storeOrderNumbers?.map((orderNumber, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Typography>
                    {index + 1}) {orderNumber}
                  </Typography>
                </Box>
              ))}
              <Typography>{notForStore}</Typography>
              <Typography>Teslimat Kodu: {deliveryPassword}</Typography>
              <Box display="flex" alignItems="center" sx={{ width: 350 }}>
                <Checkbox
                  checked={isCheckboxChecked2}
                  onChange={() => setIsCheckboxChecked2(!isCheckboxChecked2)}
                />
                <Typography>
                  Gönderi kodlarını ilgili paketlerin üzerine yazdım.
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!isCheckboxChecked2}
              onClick={() => {
                setSuccessCreated(false);
                window.location.reload(false);
              }}
              color="primary"
            >
              Tamam
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={failed} onClose={() => setFailed(false)}>
          <DialogTitle color={'red'}>Hata</DialogTitle>
          <DialogContent>
            <Typography>İşleminiz gerçekleştirilemedi.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFailed(false)} color="primary">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={rejectedSuccess}
          onClose={() => {
            window.location.reload(false);
            setRejectedSuccess(false);
          }}
        >
          <DialogTitle color={'green'}>Başarılı</DialogTitle>
          <DialogContent>
            <Typography>Talep reddedilmiştir.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.location.reload(false);
                setRejectedSuccess(false);
              }}
              color="primary"
            >
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
}
