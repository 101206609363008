import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText, Typography } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { tokenService } from 'src/services';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const generateCombinedPermissions = (
    accountCategory,
    frontAuthArray,
    userType
  ) => {
    return frontAuthArray.map(
      (frontAuth) => `${accountCategory}:${frontAuth}:${userType}`
    );
  };

  const groupByCategory = (data) => {
    return data.reduce((groups, item) => {
      const category = item.category || '';
      groups[category] = groups[category] || [];
      groups[category].push(item);
      return groups;
    }, {});
  };

  const user = tokenService.getUser();
  const accountCategory = user?.accountCategory || '';
  const userType = user?.type;
  const frontAuthArray = user?.frontAuth || [];
  const combinedPermissions = generateCombinedPermissions(
    accountCategory,
    frontAuthArray,
    userType
  );

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {Object.entries(groupByCategory(data)).map(([category, items]) => {
          const filteredItems = items.filter((item) => {
            // if (
            //   tokenService?.getUser()?.isHandsfree === false &&
            //   item.title === 'Hands Free'
            // ) {
            //   return false;
            // }

            if (
              item.permissionCombination &&
              item.permissionCombination.length > 0
            ) {
              if (
                !item.permissionCombination.every((perm) =>
                  frontAuthArray.includes(perm)
                )
              ) {
                return false;
              }
            }
            return item.permissions.some((permission) =>
              combinedPermissions.includes(permission)
            );
          });

          if (filteredItems.length === 0) {
            return null;
          }

          return (
            <React.Fragment key={category}>
              {category && category !== 'Emanet İşlemleri' && (
                <Typography
                  sx={{
                    color: '#98ABEA',
                    fontSize: 12,
                    marginLeft: '20px',
                    fontWeight: '600',
                    marginBlock: '5px',
                  }}
                >
                  {category}
                </Typography>
              )}
              {filteredItems.map((item, index) => {
                if (item.accordion && index === 0) {
                  return (
                    <Accordion
                      key={index}
                      sx={{ bgcolor: 'transparent', boxShadow: 'none' }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: '#E7EAFA' }} />
                        }
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          color: '#E7EAFA',
                          fontSize: 14,
                          paddingLeft: '0px',
                          minHeight: '48px',
                          '&.Mui-expanded': {
                            color: '#E7EAFA',
                            height: '48px',
                            minHeight: '40px',
                            alignItems: 'center',
                            display: 'flex',
                          },
                        }}
                      >
                        <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>
                        <ListItemText
                          disableTypography
                          primary={item.category}
                          sx={{ textAlign: 'left', marginLeft: '0px' }}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        {data
                          .filter(
                            (subItem) => subItem.category === item.category
                          )
                          .map((subItem, subIndex) => (
                            <NavItem key={subIndex} item={subItem} />
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                }
                else if (!item.accordion) {
                  return <NavItem key={index} item={item} />;
                }
              })}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: '#E7EAFA',
        '&.active': {
          color: '#E7EAFA',
          bgcolor: '#0666F6',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon}</StyledNavItemIcon>
      <ListItemText
        disableTypography
        primary={
          tokenService?.getUser()?.accountCategory === 'store' &&
            title === 'Hands Free'
            ? tokenService?.getUser()?.authLocations?.branch[0]?.mallID ===
              '98569271082166328155'
              ? 'Bags-Free'
              : 'Hands Free'
            : title
        }
      />
      {info && info}
    </StyledNavItem>
  );
}
