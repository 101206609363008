import {
  Button,
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
  Box,
  Modal,
  TextField,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import api from 'src/services/BaseService';
import { saveAs } from 'file-saver';
import {
  Document,
  Packer,
  Paragraph,
  ImageRun,
  UnderlineType,
  AlignmentType,
  TextRun,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
  Table,
  VerticalAlign
} from 'docx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocalization } from 'src/context/LocalizationContext';
import logo from '../assets/images/qr-logo.png';
import { tokenService } from 'src/services';
import ClearButton from 'src/components/shared/ClearButton';

export default function SettingsPage() {
  const { language } = useLocalization();
  const customer = useSelector((state) => state.customerReducer);
  const [branches, setBranches] = useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [showCheckMessage, setShowCheckMessage] = useState(false);
  const [checkMessage, setCheckMessage] = useState(
    'Bu işlemi tamamlarsanız QR Kodunuz yenilenecektir ve eski QR kodunuz kullanılmaz hale gelecektir. Bu işlem geri çevrilemez, bu işlemi tamamlamak istediğinize emin misiniz?'
  );
  const [checkMessageTitle, setCheckMessageTitle] = useState('');
  const [showResultBox, setShowResultBox] = useState(false);
  const [refreshID, setRefreshID] = useState('');
  const [refresLoading, setRefresLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [isBulkRefresh, setIsBulkRefresh] = useState(false);

  const [filterText, setFilterText] = useState('');

  const filteredBranches = branches.filter((branch) =>
    branch.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userType, setUserType] = useState(tokenService.getUser()?.type);

  const handleOpenBulkCheckMessage = () => {
    setIsBulkRefresh(true);
    setShowCheckMessage(true);
  };

  useEffect(() => {
    if (showCheckMessage) {
      setIsButtonDisabled(true);
      setProgress(0);

      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 99.99) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 100;
          }
          return prevProgress + 3.333;
        });
      }, 100);

      return () => clearInterval(timer);
    } else {
      setIsButtonDisabled(true);
      setProgress(0);
    }
  }, [showCheckMessage]);

  const handleSelect = (branch) => {
    const currentIndex = checkedItems.findIndex(
      (item) => item.id === branch.id
    );
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(branch);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);
  };

  const handleOpenCheckMessage = (id) => {
    setShowCheckMessage(true);
    setRefreshID(id);
  };

  const renewQRCode = () => {
    setCheckedItems([]);
    setRefresLoading(true);
    if (selectedType === '') {
      api
        .post('corporations/update-static-qr', {
          branchID: refreshID,
        })
        .then((res) => {
          if (res.data.status) {
            setCheckMessage('QR Kodu başarılı bir şekilde yenilendi.');
            setCheckMessageTitle('Başarılı');
            fetchData();
            setRefresLoading(false);
            setShowCheckMessage(false);
            setShowResultBox(true);
          } else {
            setCheckMessage('QR Kod Yenilenirken bir hata oluştu.');
            setCheckMessageTitle('Hata Meydana Geldi');
            setRefresLoading(false);
            setShowCheckMessage(false);
            setShowResultBox(true);
          }
        })
        .catch((err) => {
          console.log('QR Renew ERR:', err);
        });
    } else {
      api
        .post('locations/update-static-qr', {
          locationID: refreshID,
        })
        .then((res) => {
          if (res.data.status) {
            setCheckMessage('QR Kodu başarılı bir şekilde yenilendi.');
            setCheckMessageTitle('Başarılı');
            fetchData();
            setRefresLoading(false);
            setShowCheckMessage(false);
            setShowResultBox(true);
          } else {
            setCheckMessage('QR Kod Yenilenirken bir hata oluştu.');
            setCheckMessageTitle('Hata Meydana Geldi');
            setRefresLoading(false);
            setShowCheckMessage(false);
            setShowResultBox(true);
          }
        })
        .catch((err) => {
          console.log('QR Renew ERR:', err);
        });
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      setCheckedItems(branches);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (checkedItems.length < branches.length) {
      setSelectAll(false);
    } else if (checkedItems.length === branches.length) {
      setSelectAll(true);
    }
  }, [checkedItems, branches]);

  const fillEmptyBranchQR = async (branch) => {
    try {
      const res = await api.post('corporations/get-static-qr', {
        branchID: branch.branchID,
      });
      if (res.data.status) {
        return res.data.result;
      }
    } catch (err) {
      console.log(err);
    }
    return branch.locationStaticQR; // Eğer QR kod alınamazsa mevcut değeri döndür
  };

  const fillEmptyLocationQR = async (location) => {
    try {
      const res = await api.post('locations/get-static-qr', {
        locationID: location.locationID,
      });
      if (res.data.status) {
        return res.data.result;
      }
    } catch (err) {
      console.log(err);
    }
    return location.locationStaticQR; // Eğer QR kod alınamazsa mevcut değeri döndür
  };

  const renewSelectedQRCodes = async () => {
    setIsBackdropOpen(true);
    setShowCheckMessage(false)

    let successCount = 0;
    let errorCount = 0;

    // Seçili her öğe için API çağrısı yapıyoruz
    for (const item of checkedItems) {
      try {
        let res;
        if (selectedType === '') {
          // Master QR için
          res = await api.post('corporations/update-static-qr', {
            branchID: item.id,
          });
        } else {
          // Store, warehouse, office için
          res = await api.post('locations/update-static-qr', {
            locationID: item.id,
          });
        }
        if (res.data.status) {
          successCount++;
        } else {
          errorCount++;
        }
      } catch (err) {
        console.error('QR yenileme hatası:', err);
        errorCount++;
      }
    }

    setIsBackdropOpen(false);

    // Sonuç mesajı belirleyelim
    if (errorCount === 0) {
      setCheckMessage('Tüm seçilen QR kodları başarılı bir şekilde yenilendi.');
      setCheckMessageTitle('Başarılı');
    } else {
      setCheckMessage(
        `${successCount} QR kodu başarıyla yenilendi, ${errorCount} QR kodu yenileme sırasında hata oluştu.`
      );
      setCheckMessageTitle('Kısmi Başarısız');
    }
    setShowResultBox(true);
    // Verileri güncelleyin ve seçimleri temizleyin
    fetchData();
    setCheckedItems([]);
    setSelectAll(false);
  };


  const fetchData = async () => {
    if (customer) {
      let fileTitle = '';
      if (selectedType === '') {
        fileTitle = customer?.customer?.title + ' Master QR Listesi.docx';
      } else if (selectedType === 'store') {
        fileTitle = customer?.customer?.title + ' Mağaza QR Listesi.docx';
      } else if (selectedType === 'warehouse') {
        fileTitle = customer?.customer?.title + ' Depo QR Listesi.docx';
      } else if (selectedType === 'office') {
        fileTitle = customer?.customer?.title + ' Ofis QR Listesi.docx';
      }
      setFileName(fileTitle);

      try {
        const res = await api.post('flow/get-malls-branches', {
          mallID: customer?.customer?.id,
          withLocations: true,
        });

        if (res.data.status) {
          let mappedBranches = await Promise.all(
            res.data.result.map(async (x) => {
              const locationStaticQR =
                x.locationStaticQR || (await fillEmptyBranchQR(x));
              return {
                id: x.branchID,
                title: x.title,
                locationStaticQR,
                locationID: x.locations[0]?.locationID,
                companyLegalTitle: x.companyLegalTitle,
                locations: x.locations,
              };
            })
          );

          const sortedBranches = mappedBranches.sort((a, b) =>
            a.title.localeCompare(b.title)
          );

          const allLocationsWithCombinedTitles = await Promise.all(
            sortedBranches.flatMap(async (item) => {
              return await Promise.all(
                item.locations.map(async (location) => {
                  const locationStaticQR =
                    location.locationStaticQR ||
                    (await fillEmptyLocationQR(location));
                  return {
                    ...location,
                    title: location.type !== "warehouse" ? item.title : `${item.title} (${location.title})`,
                    companyLegalTitle: item.companyLegalTitle,
                    locationStaticQR,
                    id: location.locationID,
                    type: location.type,
                  };
                })
              );
            })
          );

          const stores = allLocationsWithCombinedTitles
            .flat()
            .sort((a, b) => a.title.localeCompare(b.title))
            .filter((x) => x.type === 'store');
          const warehouses = allLocationsWithCombinedTitles
            .flat()
            .sort((a, b) => a.title.localeCompare(b.title))
            .filter((x) => x.type === 'warehouse');
          const offices = allLocationsWithCombinedTitles
            .flat()
            .sort((a, b) => a.title.localeCompare(b.title))
            .filter((x) => x.type === 'office');

          if (selectedType === '') {
            setBranches(sortedBranches);
          } else if (selectedType === 'store') {
            setBranches(stores);
          } else if (selectedType === 'warehouse') {
            setBranches(warehouses);
          } else if (selectedType === 'office') {
            setBranches(offices);
          }
        } else {
          setBranches([]);
        }
      } catch (err) {
        console.log(err);
        setBranches([]);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [customer, selectedType]);

  const exportToWord = async () => {
    setIsBackdropOpen(true);
    if (checkedItems.length === 0) {
      console.error('No branches to process.');
      setIsBackdropOpen(false);
      return;
    }

    // Logoyu yükleyelim
    const logoUrl = logo;
    let logoData = null;
    try {
      const logoResponse = await fetch(logoUrl);
      if (!logoResponse.ok) {
        throw new Error("Logo could not be fetched");
      }
      logoData = await logoResponse.arrayBuffer();
    } catch (error) {
      console.error("Error fetching logo:", error);
    }

    const paragraphs = [];
    const itemsPerPage = 9; // Her sayfada maksimum 9 öğe
    const columns = 3;

    // Seçilen öğeleri sayfalara ayıralım
    const pages = [];
    for (let i = 0; i < checkedItems.length; i += itemsPerPage) {
      pages.push(checkedItems.slice(i, i + itemsPerPage));
    }

    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
      const pageItems = pages[pageIndex];
      // İlk sayfa dışındakiler için sayfa kırması ekleyelim
      if (pageIndex > 0) {
        paragraphs.push(new Paragraph({ text: '', pageBreakBefore: true }));
      }

      const gridRows = [];
      // Satır sayısını her zaman 3 olarak belirleyelim (3 sütun)
      const rowsCount = Math.ceil(pageItems.length / columns);
      for (let row = 0; row < rowsCount; row++) {
        const rowCells = [];
        for (let col = 0; col < columns; col++) {
          const index = row * columns + col;
          if (index < pageItems.length) {
            const branch = pageItems[index];
            let imageBuffer = null;
            try {
              const response = await fetch(branch.locationStaticQR);
              if (!response.ok) {
                throw new Error('QR Code could not be fetched');
              }
              imageBuffer = await response.arrayBuffer();
            } catch (error) {
              console.error('Error fetching QR for branch:', branch.title, error);
            }

            const cellChildren = [];
            if (imageBuffer) {
              cellChildren.push(
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: imageBuffer,
                      transformation: {
                        width: 150, // QR kod boyutu
                        height: 150,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              );
            } else {
              cellChildren.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: branch.title + ' QR Code Error',
                      bold: true,
                      size: 32,
                      color: 'fc031c',
                      underline: { type: UnderlineType.SINGLE, color: 'fc031c' },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              );
            }
            cellChildren.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: branch.title,
                    bold: true,
                    size: 28,
                    font: 'Trebuchet MS',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              })
            );
            cellChildren.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'panel.malllogistics.com',
                    size: 20,
                    color: '9c9c9c',
                    font: 'Trebuchet MS',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              })
            );
            cellChildren.push(
              new Paragraph({
                text: '',
                spacing: { before: 200 },
              })
            );
            if (logoData) {
              cellChildren.push(
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: logoData,
                      transformation: { width: 83.81, height: 31.93 },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              );
            }

            rowCells.push(
              new TableCell({
                children: cellChildren,
                width: { size: Math.floor(100 / columns), type: WidthType.PERCENTAGE },
                verticalAlign: VerticalAlign.CENTER,
                margins: { top: 100, bottom: 100, left: 100, right: 100 },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                },
              })
            );
          } else {
            // Doldurucu (filler) hücre: içeriği yok, görünmez şekilde tanımlanıyor.
            rowCells.push(
              new TableCell({
                children: [new Paragraph("")],
                width: { size: Math.floor(100 / columns), type: WidthType.PERCENTAGE },
                verticalAlign: VerticalAlign.CENTER,
                margins: { top: 0, bottom: 0, left: 0, right: 0 },
                borders: {
                  top: { style: BorderStyle.NONE, size: 0 },
                  bottom: { style: BorderStyle.NONE, size: 0 },
                  left: { style: BorderStyle.NONE, size: 0 },
                  right: { style: BorderStyle.NONE, size: 0 },
                },
              })
            );
          }
        }
        gridRows.push(new TableRow({ children: rowCells }));
      }

      paragraphs.push(
        new Table({
          rows: gridRows,
          width: { size: 100, type: WidthType.PERCENTAGE },
        })
      );
    }

    const doc = new Document({
      sections: [
        {
          children: paragraphs,
        },
      ],
    });

    Packer.toBlob(doc)
      .then((blob) => {
        saveAs(blob, fileName);
        setIsBackdropOpen(false);
        setSuccessMessageOpen(true);
      })
      .catch((error) => {
        console.error("Error during document generation:", error);
        setIsBackdropOpen(false);
      });
  };


  const exportToWordFullPage = async () => {
    setIsBackdropOpen(true);
    if (checkedItems.length === 0) {
      console.error('No branches to process.');
      setIsBackdropOpen(false);
      return;
    }

    // Logo assets dosyanızdaki logonun URL'sini kullanın.
    const logoUrl = logo;
    let logoData = null;
    try {
      const logoResponse = await fetch(logoUrl);
      if (!logoResponse.ok) {
        throw new Error("Logo could not be fetched");
      }
      // ArrayBuffer olarak alıyoruz ki docx ImageRun bunu bekliyor.
      logoData = await logoResponse.arrayBuffer();
    } catch (error) {
      console.error("Error fetching logo:", error);
    }

    const paragraphs = [];
    const copiesPerStore = 9;
    const columns = 3;

    for (let i = 0; i < checkedItems.length; i++) {
      // İlk mağaza dışındakiler için sayfa kırması ekle
      if (i > 0) {
        paragraphs.push(new Paragraph({ text: '', pageBreakBefore: true }));
      }

      const branch = checkedItems[i];
      let imageBuffer = null;
      try {
        const response = await fetch(branch.locationStaticQR);
        if (!response.ok) {
          throw new Error('QR Code could not be fetched');
        }
        // QR kod verisini ArrayBuffer olarak alıyoruz
        imageBuffer = await response.arrayBuffer();
      } catch (error) {
        console.error('Error fetching QR for branch:', branch.title, error);
      }

      // Grid yapısını oluşturmak için tablo satırları ve hücreleri hazırlıyoruz.
      const gridRows = [];
      const rowsCount = Math.ceil(copiesPerStore / columns);
      for (let row = 0; row < rowsCount; row++) {
        const rowCells = [];
        for (let col = 0; col < columns; col++) {
          const index = row * columns + col;
          if (index < copiesPerStore) {
            const cellChildren = [];

            if (imageBuffer) {
              cellChildren.push(
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: imageBuffer,
                      transformation: {
                        width: 150, // QR kod boyutu
                        height: 150,
                      },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              );
            } else {
              cellChildren.push(
                new Paragraph({
                  children: [
                    new TextRun({
                      text: branch.title + ' QR Code Error',
                      bold: true,
                      size: 32,
                      color: 'fc031c',
                      underline: { type: UnderlineType.SINGLE, color: 'fc031c' },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              );
            }

            cellChildren.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: branch.title,
                    bold: true,
                    size: 28,
                    font: 'Trebuchet MS',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              })
            );

            cellChildren.push(
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'panel.malllogistics.com',
                    size: 16,
                    color: '9c9c9c',
                    font: 'Trebuchet MS',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              })
            );

            cellChildren.push(
              new Paragraph({
                text: '',
                spacing: { before: 200 },
              })
            );

            if (logoData) {
              cellChildren.push(
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: logoData,
                      transformation: { width: 83.81, height: 31.93 },
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                })
              );
            }

            rowCells.push(
              new TableCell({
                children: cellChildren,
                width: {
                  size: Math.floor(100 / columns),
                  type: WidthType.PERCENTAGE,
                },
                verticalAlign: VerticalAlign.CENTER,
                margins: { top: 100, bottom: 100, left: 100, right: 100 },
                borders: {
                  top: { style: BorderStyle.NONE },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                },
              })
            );
          }
        }
        gridRows.push(new TableRow({ children: rowCells }));
      }

      paragraphs.push(
        new Table({
          rows: gridRows,
          width: { size: 100, type: WidthType.PERCENTAGE },
        })
      );
    }

    // Artık footer'da logo eklemiyoruz.
    const doc = new Document({
      sections: [
        {
          children: paragraphs,
        },
      ],
    });

    Packer.toBlob(doc)
      .then((blob) => {
        saveAs(blob, fileName);
        setIsBackdropOpen(false);
        setSuccessMessageOpen(true);
      })
      .catch((error) => {
        console.error("Error during document generation:", error);
        setIsBackdropOpen(false);
      });
  };

  const groupBranchesByLetter = (branches) => {
    return branches.reduce((acc, branch) => {
      const letter = branch.title[0].toUpperCase();
      if (!acc[letter]) {
        acc[letter] = [];
      }
      acc[letter].push(branch);
      return acc;
    }, {});
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
    setCheckedItems([]);
    setSelectAll(false);
    setFilterText("")
  };

  const groupedBranches = groupBranchesByLetter(filteredBranches);

  return (
    <>
      <Helmet>
        <title> {language.settings_page.titles.site_title}</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.settings_page.titles.title}
          </Typography>
        </Stack>

        {branches.length > 0 ? (
          <Card sx={{ padding: '15px' }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              paddingY={2}
              paddingX={2}
            >
              <Stack sx={{ width: '100%', flexDirection: 'row' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="location-type"
                    name="locationType"
                    value={selectedType}
                    onChange={handleChangeType}
                    row
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label={language.commons.labels.master_qr}
                    />
                    <FormControlLabel
                      value="store"
                      control={<Radio />}
                      label={language.commons.store}
                    />
                    <FormControlLabel
                      value="warehouse"
                      control={<Radio />}
                      label={language.commons.labels.storage}
                    />
                    <FormControlLabel
                      value="office"
                      control={<Radio />}
                      label={language.commons.labels.office}
                    />
                  </RadioGroup>
                </FormControl>

                <Stack style={{flexDirection:'row',gap:'15px'}}>
                  <TextField
                    label="İsimle Filtrele"
                    variant="outlined"
                    size="small"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    style={{ marginBottom: '16px' }}
                  />
                  <ClearButton onClick={() => setFilterText("")} />
                </Stack>

              </Stack>

              <div>
                <Button
                  disabled={checkedItems.length === 0}
                  variant="contained"
                  onClick={exportToWord}
                  style={{
                    marginTop: '16px',
                    maxWidth: 500,
                    marginBottom: '16px',
                  }}
                >
                  {language.settings_page.titles.choose_dowloand}
                </Button>

                {(userType === "highadmin" || userType === "superadmin") &&
                  <Button
                    disabled={checkedItems.length === 0}
                    variant="contained"
                    onClick={handleOpenBulkCheckMessage}
                    style={{
                      marginTop: '16px',
                      maxWidth: 500,
                      marginBottom: '16px',
                      marginLeft: '8px',
                    }}
                  >
                    Seçilenlerin QR Kodlarını Yenile
                  </Button>
                }
                <Button
                  disabled={checkedItems.length === 0}
                  variant="contained"
                  onClick={exportToWordFullPage}
                  style={{
                    marginTop: '16px',
                    maxWidth: 500,
                    marginBottom: '16px',
                    marginLeft: '8px',
                  }}
                >
                  Seçilenleri Sayfayı Doldurarak İndir
                </Button>
                {checkedItems.length === 0 ? (
                  <Tooltip title={language.commons.labels.file_download}>
                    <IconButton
                      size="small"
                      sx={{ color: 'gray', marginLeft: '8px' }}
                      onClick={handleOpen}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={language.commons.labels.file_download}>
                    <IconButton
                      size="small"
                      sx={{
                        color: 'gray',
                        marginLeft: '8px',
                        animation: 'pulse 2s infinite',
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1)',
                            color: 'gray',
                          },
                          '50%': {
                            transform: 'scale(1.1)',
                            color: '#088AB2', // Açık mavi
                          },
                          '100%': {
                            transform: 'scale(1)',
                            color: 'gray',
                          },
                        },
                      }}
                      onClick={handleOpen}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                    justifyContent: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3 id="modal-title">
                    {language.commons.labels.warning_file_download}
                  </h3>
                  <img
                    src={require('../../src/assets/images/popUpError.png')}
                    alt="Pop-up Error"
                    style={{
                      display: 'block',
                      margin: '20px auto',
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                  <p id="modal-description">
                    {language.commons.labels.browser_message}
                  </p>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                  >
                    {language.commons.okay}
                  </Button>
                </Box>
              </Modal>

              <FormGroup sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  {Object.keys(groupedBranches).length > 0 && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        }
                        label={language.commons.labels.select_all}
                      />
                    </Grid>
                  )}

                  {Object.keys(groupedBranches).map((letter) => (
                    <Grid item xs={12} key={letter}>
                      <Typography variant="h6" gutterBottom>
                        {letter}
                      </Typography>
                      <Grid container spacing={2}>
                        {groupedBranches[letter].map((branch) => (
                          <Grid item xs={12} sm={6} md={3} key={branch.id}>
                            <FormControlLabel
                              sx={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '8px',
                                margin: '8px 0',
                              }}
                              control={
                                <Checkbox
                                  checked={checkedItems.some(
                                    (item) => item.id === branch.id
                                  )}
                                  onChange={() => handleSelect(branch)}
                                  color="primary"
                                />
                              }
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'flex-end',
                                  }}
                                >
                                  {branch.title}
                                  <Tooltip
                                    title={language.commons.labels.qr_refresh}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleOpenCheckMessage(branch.id)
                                      }
                                      aria-hidden="false"
                                      aria-label="refresh"
                                    >
                                      <RefreshIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              <Dialog open={successMessageOpen}>
                <DialogTitle>{language.commons.successful}</DialogTitle>
                <DialogContent>
                  <Typography>
                    {language.commons.successfully_download}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setSuccessMessageOpen(false);
                    }}
                    color="primary"
                  >
                    {language.commons.okay}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={showCheckMessage}>
                <DialogTitle sx={{ color: 'red' }}>Uyarı</DialogTitle>
                {!refresLoading ? (
                  <DialogContent>
                    <Typography>
                      {isBulkRefresh
                        ? 'Bu işlemi tamamlarsanız seçilen QR Kodlarınız yenilenecektir ve eski QR Kodlarınız kullanılmaz hale gelecektir. Bu işlem geri çevrilemez, bu işlemi tamamlamak istediğinize emin misiniz?'
                        : language.settings_page.titles.qr_code_warn}
                    </Typography>
                  </DialogContent>

                ) : (
                  <DialogContent>
                    <DialogContent>
                      <Typography>
                        {language.settings_page.titles.qr_code_warn2}
                      </Typography>
                    </DialogContent>
                    <Backdrop
                      sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={refresLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </DialogContent>
                )}

                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowCheckMessage(false);
                      setRefreshID('');
                      setIsBulkRefresh(false);
                    }}
                    variant="contained"
                    color="error"
                    sx={{ backgroundColor: '#DC423C', height: '35px' }}
                  >
                    {language.commons.no}
                  </Button>


                  <Button
                    onClick={() => {
                      if (isBulkRefresh) {
                        renewSelectedQRCodes();
                      } else {
                        renewQRCode();
                      }
                    }}
                    variant="contained"
                    color="success"
                    sx={{ color: 'white', backgroundColor: '#009427', height: '35px' }}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? (
                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" value={progress} sx={{ color: 'darkgray' }} size={18} />
                      </Box>
                    ) : (
                      language.commons.yes
                    )}
                  </Button>

                </DialogActions>
              </Dialog>

              <Dialog open={showResultBox}>
                <DialogTitle>{checkMessageTitle}</DialogTitle>
                <DialogContent>
                  <Typography>{checkMessage}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowResultBox(false);
                    }}
                    color="primary"
                  >
                    {language.commons.okay}
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Card>
        ) : (
          <Card sx={{ padding: '15px' }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              paddingY={2}
              paddingX={2}
            >
              <Stack sx={{ width: '100%' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="location-type"
                    name="locationType"
                    value={selectedType}
                    onChange={handleChangeType}
                    row
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label={language.commons.labels.master_qr}
                    />
                    <FormControlLabel
                      value="store"
                      control={<Radio />}
                      label={language.commons.column.store}
                    />
                    <FormControlLabel
                      value="warehouse"
                      control={<Radio />}
                      label={language.commons.labels.storage}
                    />
                    <FormControlLabel
                      value="office"
                      control={<Radio />}
                      label={language.commons.labels.office}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Typography>{language.commons.no_list}</Typography>
            </Stack>
          </Card>
        )}
      </Stack>
    </>
  );
}
