import * as React from 'react';
import Box from '@mui/material/Box';
import Scrollbar from '../components/scrollbar';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FamilyRestroomTwoTone, WarningOutlined } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Card,
  Stack,
  Typography,
  Button,
  Divider,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import api from 'src/services/BaseService';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import { useLocalization } from 'src/context/LocalizationContext';
import Services from 'src/services/Services';

registerAllModules();

export default function FullFeaturedCrudGrid() {
  const calculatedHeight = window.innerHeight - 250;
  const { language } = useLocalization();
  const [cargoList, setCargoList] = React.useState([]);
  const hotRef = React.useRef(null);
  const initialRows = Array.from({ length: 100 }, (_, index) => ({
    id: index + 1,
    barcode: '',
    name: '',
    corporation: '',
    shipmentFirm: '',
    corporationID: '',
  }));
  const columnHeaders = [
    language.commons.column.no,
    language.commons.column.date,
    language.commons.column.waybill,
    language.commons.column.cob,
    language.commons.column.time,
    language.commons.column.store,
    language.commons.column.count,
    language.commons.column.service,
    language.commons.column.transport_person,
    language.commons.column.waybill_status,
    language.commons.column.cancel_reason,
  ];
  // const cargoList = [
  //   language.commons.cargo_list.parcel_shipping,
  //   language.commons.cargo_list.small_car,
  //   language.commons.cargo_list.big_car,
  //   language.commons.cargo_list.hanging_car,
  //   language.commons.cargo_list.medium_vehicle,
  //   language.commons.cargo_list.palette,
  // ];

  const irsaliyeCancel = [
    {
      id: 1,
      name: 'Geçerli',
    },
    {
      id: 2,
      name: 'İptal',
    },
  ];
  const [porter, setPorter] = React.useState([]);
  const [mallList, setMallList] = React.useState([]);
  const [projeNo, setProjeNo] = React.useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = React.useState(true);
  const [errorIndex, setErrorIndex] = React.useState([]);
  const avmID = localStorage.getItem('mallID');
  const [copyLoading, setCopyLoading] = React.useState(false);

  const columnSettings = [
    {
      readOnly: true,
      width: 50,
      className: 'htCenter htMiddle',
    },
    {
      type: 'date',
      strict: false,
      className: 'htCenter htMiddle',
      placeholder: 'GG/AA/YYYY',
      datePickerConfig: {
        firstDay: 1,
        showWeekNumber: true,
        i18n: {
          previousMonth: 'Previous Month',
          nextMonth: 'Next Month',
          months: [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',
          ],
          weekdays: [
            'Pazar',
            'Pazartesi',
            'Salı',
            'Çarşamba',
            'Perşembe',
            'Cuma',
            'Cumartesi',
          ],
          weekdaysShort: ['Pa', 'Pzt', 'Salı', 'Ça', 'Pe', 'Cu', 'Ct'],
        },
      },
      width: 100,
    },
    {
      type: 'numeric',
      strict: false,
      className: 'htCenter htMiddle',
      width: 100,
    },
    {
      type: 'numeric',
      strict: false,
      className: 'htCenter htMiddle',
      width: 100,
      validator: (value, callback) => {
        const isValid = /^[A-Za-z0-9]*$/.test(value); // Harf ve rakam kontrolü
        callback(isValid);
      },
    },
    {
      type: 'time',
      strict: false,
      className: 'htCenter htMiddle',
      width: 100,
      timeFormat: 'HH:mm',
      placeholder: 'SS:DD',
    },
    {
      type: 'autocomplete',
      strict: true,
      className: 'htCenter htMiddle',
      width: 200,
      allowInvalid: false,
      source: mallList?.map((option) => option?.title.trim()),
      validator: function (value, callback) {
        // Girilen değerin listedeki değerlerden biri olup olmadığını kontrol et
        const sourceValues = mallList?.map((option) => option?.title.trim());
        if (sourceValues.includes(value)) {
          callback(true);
        } else {
          callback(false);
          toast.error('Listeden değer seçilmeli');
        }
      },
    },
    {
      type: 'numeric',
      strict: false,
      className: 'htCenter htMiddle',
      width: 60,
      validator: (value, callback) => {
        if (value === 0) {
          callback(false);
          toast.error('Değer 0 dan Büyük Olmalı!');
        } else if (typeof value === 'string') {
          callback(false);
          toast.error('Lütfen Bir Sayı Giriniz');
        } else {
          callback(true);
        }
      },
    },
    {
      className: 'htCenter htMiddle',
      width: 100,
      type: 'autocomplete',
      strict: true,
      source: cargoList,
      allowInvalid: false,
      validator: function (value, callback) {
        if (cargoList.includes(value)) {
          callback(true);
        } else {
          callback(false);
          toast.error('Listeden değer seçilmeli');
        }
      },
    },
    {
      className: 'htCenter htMiddle',
      width: 100,
      type: 'autocomplete',
      strict: true,
      allowInvalid: false,
      source: porter?.map((option) => {
        return option?.name + ' ' + option?.lastname;
      }),
      validator: function (value, callback) {
        const sourceValues = porter?.map(
          (option) => option?.name + ' ' + option?.lastname
        );
        if (sourceValues.includes(value)) {
          callback(true);
        } else {
          callback(false);
          toast.error('Listeden değer seçilmeli');
        }
      },
    },
    {
      type: 'autocomplete',
      strict: true,
      className: 'htCenter htMiddle',
      width: 100,
      allowInvalid: false,
      source: irsaliyeCancel.map((e) => e.name),
      defaultValue: 'Geçerli',
    },
    {
      type: 'autocomplete',
      strict: true,
      allowInvalid: false,
      className: 'htCenter htMiddle',
      width: 150,
      source: ['Yanlış Yazım', 'Hatalı Kayıt', 'Reason 3', '-'],
      defaultValue: 'Geçerli',
    },
  ];
  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [tableData, setTableData] = React.useState();
  const [failedRowsCount, setFailedRowsCount] = React.useState();
  const [controlModalOpen, setControlModalOpen] = React.useState(false);
  const [checkValidity, setCheckValidity] = React.useState(true);
  const [errorRows, setErrorRows] = React.useState([]);

  function convertDateFormat(dateString, hour) {
    if (dateString) {
      const parts = dateString?.split('/');
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      const newDateString = `${year}-${month?.padStart(2, '0')}-${day?.padStart(
        2,
        '0'
      )} ${hour}:00`;

      return newDateString;
    }
  }

  function findClosestValue(userInput, valuesArray, whichFunc) {
    let closestValue = null;
    let minDistance = Infinity;

    if (whichFunc === 'service') {
      for (let i = 0; i < valuesArray.length; i++) {
        let value = valuesArray[i];
        let distance = levenshteinDistance(
          userInput?.toLowerCase(),
          value?.toLowerCase()
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestValue = value;
        }
      }

      return closestValue;
    } else {
      for (let i = 0; i < valuesArray.length; i++) {
        let value = valuesArray[i];
        let distance = levenshteinDistance(
          userInput?.toLowerCase(),
          value.name?.toLowerCase()
        );
        if (distance < minDistance) {
          minDistance = distance;
          closestValue = value;
        }
      }

      return closestValue;
    }
  }
  function levenshteinDistance(str1, str2) {
    if (!str1 || !str2) {
      return;
    }
    let len1 = str1?.length;
    let len2 = str2?.length;
    let dp = [];

    for (let i = 0; i <= len1; i++) {
      dp[i] = [];
      for (let j = 0; j <= len2; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          let cost = str1[i - 1] !== str2[j - 1] ? 1 : 0;
          dp[i][j] = Math.min(
            dp[i - 1][j] + 1,
            dp[i][j - 1] + 1,
            dp[i - 1][j - 1] + cost
          );
        }
      }
    }

    return dp[len1][len2];
  }

  const handleCreate = () => {
    setCheckValidity(false);
    setTimeout(() => {
      setCheckValidity(true);
    }, 2000);

    if (checkValidity) {
      const table = hotRef.current.hotInstance.getData();
      const newErrorIndex = [];
      const newErrorRows = [];

      const nonEmptyRows = table
        .filter((row) => {
          if (row[9] === 'Geçerli') {
            const emptyAndFilledCells = {
              rowIndex: row[0],
              emptyCells: [],
              filledCells: [],
            };

            row.slice(1, -1).forEach((cell, index) => {
              if (cell === null || cell === undefined || cell === '') {
                emptyAndFilledCells.emptyCells.push({
                  emptyCellIndex: index + 1,
                  row: row.slice(1, -1),
                });
              } else {
                emptyAndFilledCells.filledCells.push({
                  filledCellIndex: index + 1,
                  value: cell,
                });
              }
            });

            if (emptyAndFilledCells.emptyCells.length > 0) {
              const emptyCellIndices = emptyAndFilledCells.emptyCells.map(
                (cell) => cell.emptyCellIndex
              );
              newErrorIndex.push({
                rowIndex: emptyAndFilledCells.rowIndex,
                emptyCellIndices: emptyCellIndices,
              });
              newErrorRows.push(row); // Hatalı satırı errorRows listesine ekle
            }
          } else if (row[9] === 'İptal') {
            const requiredFields = [2, 3, 10];
            const emptyFields = requiredFields.filter(
              (index) =>
                !row[index] ||
                row[index] === null ||
                row[index] === '' ||
                row[index] === undefined
            );

            if (emptyFields.length > 0) {
              newErrorIndex.push({
                rowIndex: row[0],
                emptyCellIndices: emptyFields,
              });
              newErrorRows.push(row); // Hatalı satırı errorRows listesine ekle
            }
          }
          setErrorIndex(newErrorIndex);
          setErrorRows(newErrorRows); // errorRows state'ini güncelle

          if (row[9] === 'Geçerli') {
            return row
              .slice(1, -1)
              .every(
                (cell) => cell !== null && cell !== undefined && cell !== ''
              );
          } else {
            if (
              row[2] !== '' &&
              row[3] !== '' &&
              row[2] !== undefined &&
              row[3] !== undefined &&
              row[2] !== null &&
              row[3] !== null &&
              row[10] !== null &&
              row[10] !== '' &&
              row[10] !== undefined &&
              row[9] !== null &&
              row[9] !== '' &&
              row[9] !== undefined
            ) {
              return true;
            }
            return false;
          }
        })
        .map((row) => {
          const date = row[1];
          const irsaliye = row[2];
          const kocan = row[3];
          const hours = row[4];
          const store = row[5];
          const count = row[6];
          const service = row[7];
          const transportation = row[8];
          const canceled = row[9];
          const canceledReason = row[10];

          const matchId = mallList?.find((item) => {
            const itemTrim = item.title.trim();
            return itemTrim === store;
          });

          const macthingPorterId = porter?.find((item) => {
            const fullNames = item?.name + ' ' + item?.lastname;
            return fullNames === transportation;
          });

          const convertedDate = convertDateFormat(date, hours);

          return {
            branchID: matchId?.branchID,
            createdDateTime: convertedDate,
            details: [
              {
                transportationTool: service,
                transportationToolCount: count,
              },
            ],
            porterID: macthingPorterId?.id,
            porterNameLastname:
              macthingPorterId?.name + ' ' + macthingPorterId?.lastname,
            documentNo: matchId?.id,
            poiTitle: store,
            documentNo: [
              {
                mallID: matchId?.mallID,
                seriNo: kocan,
                irsaliyeNo: irsaliye,
                reason: canceledReason,
              },
            ],
            isCancelled: canceled === 'Geçerli' ? false : true,
            index: row[0], // Burada orijinal satır numarasını koruyoruz
          };
        })
        .filter(Boolean);

      if (nonEmptyRows?.length > 0) {
        nonEmptyRows.forEach((rower) => {
          api
            .post('handshakes/create-manuel', rower)
            .then((response) => {
              const numJobsCreated = nonEmptyRows.length;
              if (response.data.status === true) {
                let newData = Array.from({ length: 100 }, (_, i) => [
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                ]);

                // Hatalı satırları en üste taşı ancak numaraları değiştirme
                newErrorRows.forEach((row, index) => {
                  newData[index] = row;
                });

                // Hatasız satırları sıfırdan numaralandırarak taşı
                nonEmptyRows.forEach((row, index) => {
                  if (!newErrorRows.includes(row)) {
                    newData[newErrorRows.length + index] = row;
                  }
                });

                newData.forEach((row, index) => {
                  row[0] = index + 1;
                });

                setTableData(newData);
                openSuccessModal(
                  `Toplam ${numJobsCreated} adet HandShake oluştu.`,
                  newErrorRows.length
                );
              } else {
                openSuccessModal(
                  'Lütfen boş yada hatalı alan bırakmayınız',
                  true
                );
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        });
      } else {
        openSuccessModal('Lütfen boş yada hatalı alan bırakmayınız', true);
      }
    } else {
      toast.error('Lütfen Hatalı Sutünları düzenleyiniz');
    }
  };

  const openSuccessModal = (message, errorCount) => {
    setSuccessMessage(message);
    setFailedRowsCount(errorCount);
    setSuccessModalOpen(true);
  };

  function getMalls(token) {
    Services.GetMalls(token).then((response) => {
      if (response.data.status) {
        const avmID = localStorage.getItem('mallID');
        const foundMall = response.data.result.find(x => x.id === avmID)?.transportationTools;
        if (foundMall) {
          //console.log("foundMall", foundMall);
          setCargoList(foundMall);
        }
      }
    });
  }

  React.useEffect(() => {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    getMalls(parsedUser.token);
    const mall = localStorage.getItem('mallID');
    api
      .post('users/get-users', { type: 'porter', mallID: mall })
      .then((response) => {
        const result = response.data.result;
        setPorter(result);
      });
    api.post('flow/get-malls-branches', { mallID: mall }).then((response) => {
      const { result } = response.data;

      setMallList(
        result.map((e) => {
          let words = e.title.toLowerCase()?.split(' ');
          for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
          }
          return { ...e, title: words.join(' ') };
        })
      );
    });
    api.get('malls/get-malls', { withOffices: true }).then((response) => {
      const { result } = response.data;
      setProjeNo(result);

      setIsBackdropOpen(false);
    });
  }, []);

  React.useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.addHook('afterChange', onAfterChange(porter));
      hotInstance.addHook(
        'afterAutofill',
        function (changes, source, direction) {
          if (direction.from.col === 2) {
            const newValue = changes[0][0];
            const startFrom = direction.from;
            const endFrom = direction.to;

            const diff = 1;
            let value = newValue;

            const endRow = endFrom.row;
            for (let row = startFrom.row; row <= endRow; row++) {
              value += diff;
              hotInstance.setDataAtCell(row, startFrom.col, value);
            }
          }
        }
      );
      hotInstance.addHook('beforeChange', function (changes, source) {
        if (source === 'Autofill.fill') {
          setCopyLoading(true);
        }
      });

      hotInstance.updateSettings({
        data: initialRows.map((row) => [
          row.id,
          row.barcode,
          row.name,
          row.corporation,
          row.shipmentFirm,
          row.corporationID,
        ]),
        columns: columnSettings,
      });
    }
  }, [hotRef, mallList, porter]);

  const onAfterChange = (porterList) => (changes, source) => {
    const hotInstance = hotRef.current.hotInstance;

    if (source === 'Autofill.fill') {
      setCopyLoading(false);
    }
    const dataToSet = [];
    const dataToCell = [];
    changes?.forEach(([row, col, prevValue, newValue]) => {
      const currentValue = hotInstance.getDataAtCell(row, col);

      if (col === 5 && newValue !== '') {
        if (source === 'CopyPaste.paste') {
          newValue = newValue.toLocaleLowerCase('tr')?.split(' ');
          for (let i = 0; i < newValue.length; i++) {
            newValue[i] =
              newValue[i].charAt(0).toUpperCase() + newValue[i].slice(1);
          }
          let asok = newValue?.join(' ');
          if (newValue !== currentValue) {
            dataToSet.push([row, col, asok]);
          }
        }


      } else if (col === 1) {
        if (prevValue !== null) {
          let formattedValue = newValue?.replace(/\./g, '/');
          if (newValue?.includes('.') || newValue?.includes('-')) {
            formattedValue = newValue?.replace(/\./g, '/');
            formattedValue = formattedValue?.replace(/-/g, '/');
          }
          if (
            !newValue?.includes('.') &&
            !newValue?.includes('-') &&
            newValue?.length === 8
          ) {
            formattedValue = newValue?.replace(
              /(\d{2})(\d{2})(\d{4})/,
              '$1/$2/$3'
            );
          }
          if (formattedValue && formattedValue !== currentValue) {
            // hotInstance.setDataAtCell(row, 1, formattedValue);
            dataToSet.push([row, 1, formattedValue]);
          }
        }
      }
      // else if (col === 2 && newValue !== ''){

      //   if(prevValue === ''){
      //     dataToSet.push([row, 9, 'Geçerli']);

      //   }

      // }
      else if (col === 9) {
        if (newValue === 'Geçerli' || newValue === '') {
          dataToSet.push([row, 10, '-']);
          dataToCell.push([row, 10, 'readOnly', true]);
          //hotInstance.setCellMeta(row, 10, 'readOnly', true);
          //hotInstance.setDataAtCell(row, 10, '-');
          //hotInstance.render();
        } else {
          dataToCell.push([row, 10, 'readOnly', false]);
          hotInstance.setCellMeta(row, 10, 'readOnly', false);
          dataToSet.push([row, 10, '']);
        }
      } 
      // else if (col === 7) {
      //   let closestValue = findClosestValue(newValue, cargoList, 'service');
      //   const currentValue = hotInstance.getDataAtCell(row, col);
      //   if (closestValue !== currentValue) {
      //     //hotInstance.setDataAtCell(row, col, closestValue);
      //     dataToSet.push([row, col, closestValue]);
      //   }
      // } 
      else if (col === 4 && newValue !== '') {
        let formattedTime = '';

        if (/^\d{4}$/.test(newValue)) {
          // 4 basamaklı bir sayıysa örneğin 1523 -> 15:23
          formattedTime = newValue?.replace(/(\d{2})(\d{2})/, '$1:$2');
        } else if (/^\d{3}$/.test(newValue)) {
          // 3 basamaklı bir sayıysa örneğin 221 -> 02:21
          formattedTime = newValue?.replace(/(\d{1})(\d{2})/, '0$1:$2');
        } else if (/^\d{2}$/.test(newValue)) {
          // 2 basamaklı bir sayıysa örneğin 11 -> 01:01
          formattedTime = newValue?.replace(/(\d{1})(\d{1})/, '0$1:0$2');
        } else if (/^\d{1}$/.test(newValue)) {
          // 1 basamaklı bir sayıysa örneğin 1 -> 01:01
          formattedTime = '0' + newValue + ':01';
        }

        // Sadece formattedTime tanımlandıysa ve currentValue ile aynı değilse hücreyi güncelle
        if (formattedTime && formattedTime !== currentValue) {
          //hotInstance.setDataAtCell(row, col, formattedTime);
          dataToSet.push([row, col, formattedTime]);
        }
      } else if (col === 3) {
        if (newValue !== newValue.toUpperCase()) {
          const updatedValue = newValue.toUpperCase();
          dataToSet.push([row, 3, updatedValue]);
        }
      }
    });

    if (dataToSet.length > 0) {
      hotInstance.setDataAtCell(dataToSet);
    }

    if (dataToCell.length > 0) {
      dataToCell.forEach(([row, col, key, value]) => {
        hotInstance.setCellMeta(row, col, key, value);
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> {language.manuel_handshake.titles.site_title} </title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={copyLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.manuel_handshake.titles.title}
          </Typography>

          <div>
            <Button
              disabled={!checkValidity}
              onClick={() => {
                handleCreate();
              }}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ marginRight: '12px' }}
            >
              {language.manuel_handshake.titles.save}
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <Box sx={{ width: '100%' }}>
              <HotTable
                viewportColumnRenderingOffset={300}
                ref={hotRef}
                rowHeights={35}
                licenseKey="non-commercial-and-evaluation"
                data={tableData}
                persistentState={true}
                colHeaders={columnHeaders}
                columns={columnSettings}
                stretchH="all"
                height={calculatedHeight}
              />
            </Box>
          </Scrollbar>
        </Card>
        <Modal
          open={isSuccessModalOpen}
          onClose={() => {
            setSuccessModalOpen(false);
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 700,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            {successMessage.includes(
              'Lütfen boş yada hatalı alan bırakmayınız'
            ) ? (
              <WarningOutlined sx={{ color: 'orange', fontSize: 64 }} />
            ) : (
              <CheckCircleIcon sx={{ color: 'green', fontSize: 64 }} />
            )}
            <Typography variant="h6" marginBottom={'15px'}>
              {successMessage}
            </Typography>
            <Typography variant="h6">
              {errorIndex.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{language.manuel_handshake.titles.row}</TableCell>
                        <TableCell>
                          {language.manuel_handshake.titles.empty_col}

                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {errorIndex.map((error) => (
                        <TableRow key={error.rowIndex}>
                          <TableCell component="th" scope="row">
                            {language.manuel_handshake.titles.row} {error.rowIndex}
                          </TableCell>
                          <TableCell>
                            {error.emptyCellIndices.join(', ')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Typography>

            <>
              <Divider sx={{ my: 2, backgroundColor: 'gray' }} />{' '}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={'15px'}
              >
                {errorIndex.length > 0 && (
                  <>
                    <div
                      style={{
                        border: '1px solid #d7d3d3',
                        padding: '7px',
                        borderRadius: '7px',
                      }}
                    >
                      <InfoOutlinedIcon sx={{ fontSize: 26 }} />
                    </div>

                    <Typography
                      variant="h6"
                      textAlign="left"
                      color={'#ff0000c9'}
                    >
                      {language.manuel_handshake.titles.warning}
                    </Typography>
                  </>
                )}

                <Button
                  style={{ border: '1px solid' }}
                  onClick={() => {
                    setSuccessModalOpen(false);
                    if (
                      !successMessage.includes('Lütfen boş alan bırakmayınız')
                    ) {
                    }
                  }}
                >
                  {language.commons.okay}
                </Button>
              </Stack>
            </>
          </Box>
        </Modal>

        <Modal
          open={controlModalOpen}
          onClose={() => setControlModalOpen(false)}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          ></Box>
        </Modal>
      </Stack>
    </>
  );
}
