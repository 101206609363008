import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Card,
  Box,
  Stack,
  Typography,
  Grid,
  Slider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

// PDF Viewer
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// React Player
import ReactPlayer from 'react-player';

// Icons
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import Services from 'src/services/Services';
import { tokenService } from 'src/services';

export default function StoreTutorialPage() {
  const token = tokenService?.getUser()?.token;
  const [selectedTab, setSelectedTab] = useState('docs');

  // API’den gelecek dokümanlar ve videolar için state
  const [docs, setDocs] = useState([]);
  const [videos, setVideos] = useState([]);
  const [docsError, setDocsError] = useState('');
  const [videosError, setVideosError] = useState('');
  const [loading, setLoading] = useState(true);

  // Seçilen doküman (doküman id’si)
  const [selectedDoc, setSelectedDoc] = useState(null);

  // Video oynatma durumları
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const hideControlsTimeout = useRef(null);

  // Zaman çubuğu için progress (0-100)
  const [played, setPlayed] = useState(0);

  // Video süresi ve anlık zamanı için state'ler
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // Her video için hesaplanan süreleri saklamak adına state
  const [videoDurations, setVideoDurations] = useState({});

  // Fullscreen durumu
  const [isFullScreen, setIsFullScreen] = useState(false);

  // ReactPlayer referansı
  const playerRef = useRef(null);
  // Fullscreen için container referansı
  const containerRef = useRef(null);

  // PDF Viewer plugin
  const getFilePluginInstance = getFilePlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    enableDownload: true,
    enablePrint: true,
    sidebarTabs: () => [],
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 1rem',
              backgroundColor: '#323639',
              borderBottom: '1px solid #ddd',
              height: '48px',
              width: '100%',
              color: 'white',
            }}
          >
            <div style={{ display: 'flex', gap: '8px' }}>
              {slots.GoToPreviousPage &&
                React.cloneElement(<slots.GoToPreviousPage />)}
              {slots.GoToNextPage && React.cloneElement(<slots.GoToNextPage />)}
              <div style={{ width: 35 }}>
                {slots.CurrentPageInput &&
                  React.cloneElement(<slots.CurrentPageInput />)}
              </div>
              <div style={{ marginTop: 5 }}>
                /{' '}
                {slots.NumberOfPages &&
                  React.cloneElement(<slots.NumberOfPages />)}
              </div>
              {slots.ZoomOut && React.cloneElement(<slots.ZoomOut />)}
              {slots.ZoomPopover && React.cloneElement(<slots.ZoomPopover />)}
              {slots.ZoomIn && React.cloneElement(<slots.ZoomIn />)}
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              {slots.Download && React.cloneElement(<slots.Download />)}
              {slots.Print && React.cloneElement(<slots.Print />)}
            </div>
          </div>
        )}
      </Toolbar>
    ),
  });

  // Yardımcı: Süreyi mm:ss formatına çevirme
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // Seçilen video değiştiğinde süre bilgilerini sıfırlıyoruz
  useEffect(() => {
    setCurrentTime(0);
    setDuration(0);
    setPlayed(0);
  }, [selectedVideoId]);

  // Fare hareketinde kontrolleri göster
  const handleMouseMove = () => {
    setShowControls(true);
    if (hideControlsTimeout.current) {
      clearTimeout(hideControlsTimeout.current);
    }
    hideControlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, 2000);
  };

  // Play/Pause butonu
  const handlePlayPause = () => {
    setPlaying((prev) => !prev);
  };

  // Fullscreen aç/kapa
  const handleToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (containerRef.current) {
        containerRef.current.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
  };

  // Fullscreen değişiminde state güncellemesi
  const handleFullScreenChange = useCallback(() => {
    const fsElement = document.fullscreenElement;
    setIsFullScreen(!!fsElement);
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  // Component unmount'ta timeout temizliği
  useEffect(() => {
    return () => {
      if (hideControlsTimeout.current) {
        clearTimeout(hideControlsTimeout.current);
      }
    };
  }, []);

  // ReactPlayer onProgress callback'ı
  const handleProgress = (state) => {
    setPlayed(state.played * 100);
    setCurrentTime(state.playedSeconds);
  };

  // Video ikonuna tıklanınca
  const handleVideoIconClick = (vid) => {
    if (vid.id === selectedVideoId) {
      setPlaying((prev) => !prev);
    } else {
      setSelectedVideoId(vid.id);
      setPlaying(true);
      setPlayed(0);
      setCurrentTime(0);
      setDuration(0);
    }
  };

  // Videoların süresini önceden yüklemek için yardımcı fonksiyon
  const loadVideoDurations = (videosArray) => {
    videosArray.forEach((video) => {
      const videoEl = document.createElement('video');
      videoEl.src = video.url;
      videoEl.preload = 'metadata';
      videoEl.addEventListener('loadedmetadata', () => {
        setVideoDurations((prev) => ({
          ...prev,
          [video.id]: videoEl.duration,
        }));
      });
      videoEl.addEventListener('error', () => {
        setVideoDurations((prev) => ({
          ...prev,
          [video.id]: 0,
        }));
      });
    });
  };

  // API’den videolar ve dokümanları çekme: Promise.all ile ortak loading kontrolü
  useEffect(() => {
    setLoading(true);
    Promise.all([
      Services.GetVideos(token, 'store'),
      Services.GetDocuments(token, 'store'),
    ])
      .then(([videosRes, docsRes]) => {
        // Videolar
        if (videosRes.data.status) {
          setVideos(
            videosRes.data.result.filter((video) => video.type === 'store')
          );
          if (videosRes.data.result.length > 0) {
            setSelectedVideoId(
              videosRes.data.result.filter((video) => video.type === 'store')[0]
                ?.id
            );
            loadVideoDurations(
              videosRes.data.result.filter((video) => video.type === 'store')
            );
          }
        } else {
          setVideos([]);
          setVideosError('Video bulunmamaktadır');
        }
        // Dokümanlar
        if (docsRes.data.status) {
          setDocs(
            docsRes.data.result.filter((video) => video.type === 'store')
          );
          if (docsRes.data.result.length > 0) {
            setSelectedDoc(
              docsRes.data.result.filter((video) => video.type === 'store')[0]
                ?.id
            );
          }
        } else {
          setDocs([]);
          setDocsError('Doküman bulunmamaktadır');
        }
      })
      .catch((err) => {
        console.log(err);
        setVideos([]);
        setDocs([]);
        setVideosError('Video bulunmamaktadır');
        setDocsError('Doküman bulunmamaktadır');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  // Seçili video ve doküman objeleri
  const selectedVideo = videos.find((vid) => vid.id === selectedVideoId);
  const selectedDocument = docs.find((doc) => doc.id === selectedDoc);

  // Eğer veri çekme devam ediyorsa, ortak loading gösterelim
  if (loading) {
    return (
      <Stack height="100vh" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Helmet>
        <title>Mağaza Eğitim Dokümanı</title>
      </Helmet>
      <Stack p={3} spacing={2}>
        {/* Üst Başlık */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">Mağaza Eğitim Dokümanı</Typography>
        </Stack>
        {/* Ana içerik */}
        <Grid container spacing={2}>
          {/* Sol Panel */}
          <Grid item xs={12} md={4}>
            <Stack spacing={2}>
              {/* Sekme Seçimi */}
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  border: '1px solid #EAECF0',
                  borderRadius: '12px',
                  overflow: 'hidden',
                }}
              >
                <Box
                  flex={1}
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ cursor: 'pointer', p: '5px' }}
                  onClick={() => setSelectedTab('videos')}
                >
                  <Box
                    sx={{
                      fontWeight: selectedTab === 'videos' ? '600' : '500',
                      color: selectedTab === 'videos' ? '#3A4A70' : '#667085',
                      backgroundColor:
                        selectedTab === 'videos' ? '#D8E8FF' : 'transparent',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      py: '10px',
                      borderRadius: '6px',
                      boxShadow:
                        selectedTab === 'videos'
                          ? '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A'
                          : 'none',
                    }}
                  >
                    Videolar
                  </Box>
                </Box>
                <Box
                  flex={1}
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ cursor: 'pointer', p: '5px' }}
                  onClick={() => setSelectedTab('docs')}
                >
                  <Box
                    sx={{
                      fontWeight: selectedTab === 'docs' ? '600' : '500',
                      color: selectedTab === 'docs' ? '#3A4A70' : '#667085',
                      backgroundColor:
                        selectedTab === 'docs' ? '#D8E8FF' : 'transparent',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      py: '10px',
                      borderRadius: '6px',
                      boxShadow:
                        selectedTab === 'docs'
                          ? '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A'
                          : 'none',
                    }}
                  >
                    Dokümanlar
                  </Box>
                </Box>
              </Stack>
              {/* İçerik Listesi */}
              <Card
                sx={{
                  position: 'relative',
                  backgroundColor: '#F5F5F5',
                  height: '500px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  scrollbarWidth: 'thin',
                  px: '20px',
                  '&::-webkit-scrollbar': { width: '6px' },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                    marginBlock: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#CFCFCF',
                    borderRadius: '4px',
                    border: '2px solid transparent',
                    backgroundClip: 'content-box',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#BFBFBF',
                  },
                  mr: '-8px',
                  pr: '8px',
                  py: '8px',
                }}
              >
                {selectedTab === 'docs' ? (
                  <>
                    <Stack direction="row" alignItems="center" mb={2}>
                      <PlaylistPlayIcon sx={{ color: '#8D8A8A', mr: 1 }} />
                      <Typography sx={{ color: '#2C2C2C', fontSize: '18px' }}>
                        {docs.length > 0
                          ? `${docs.length} Mağaza Eğitim Dökümanı`
                          : docsError || 'Doküman bulunmamaktadır'}
                      </Typography>
                    </Stack>
                    {docs.length > 0 ? (
                      docs.map((doc) => (
                        <Box
                          key={doc.id}
                          onClick={() => setSelectedDoc(doc.id)}
                          sx={{
                            p: 1.5,
                            py: 2,
                            borderRadius: 1,
                            cursor: 'pointer',
                            mb: '20px',
                            backgroundColor:
                              selectedDoc === doc.id ? '#0666F6' : '#FFFFFF',
                            color:
                              selectedDoc === doc.id ? '#F9F9F9' : '#2C2C2C',
                            fontWeight: 400,
                          }}
                        >
                          {doc.title}
                        </Box>
                      ))
                    ) : (
                      <Typography>Doküman bulunmamaktadır</Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Stack direction="row" alignItems="center" mb={2}>
                      <OndemandVideoIcon sx={{ color: '#8D8A8A', mr: 1 }} />
                      <Typography sx={{ color: '#2C2C2C', fontSize: '18px' }}>
                        {videos.length > 0
                          ? `${videos.length} Mağaza Eğitim Videosu`
                          : videosError || 'Video bulunmamaktadır'}
                      </Typography>
                    </Stack>
                    {videos.length > 0 ? (
                      videos.map((vid) => (
                        <Box
                          key={vid.id}
                          onClick={() => {
                            if (vid.id !== selectedVideoId) {
                              setSelectedVideoId(vid.id);
                              setPlaying(false);
                              setPlayed(0);
                              setCurrentTime(0);
                              setDuration(0);
                            }
                          }}
                          sx={{
                            p: 1.5,
                            py: 2,
                            borderRadius: 1,
                            mb: '20px',
                            backgroundColor:
                              selectedVideoId === vid.id
                                ? '#0666F6'
                                : '#FFFFFF',
                            color:
                              selectedVideoId === vid.id
                                ? '#F9F9F9'
                                : '#2C2C2C',
                            fontWeight: 400,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleVideoIconClick(vid);
                            }}
                          >
                            {selectedVideoId === vid.id && playing ? (
                              <PauseIcon
                                sx={{
                                  fontSize: 20,
                                  color:
                                    selectedVideoId === vid.id
                                      ? '#F9F9F9'
                                      : '#2C2C2C',
                                }}
                              />
                            ) : (
                              <PlayArrowIcon
                                sx={{
                                  fontSize: 20,
                                  color:
                                    selectedVideoId === vid.id
                                      ? '#F9F9F9'
                                      : '#2C2C2C',
                                }}
                              />
                            )}
                          </IconButton>
                          <Typography sx={{ flex: 1, marginLeft: 1 }}>
                            {vid.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                selectedVideoId === vid.id ? 'white' : '#666',
                              fontSize: '14px',
                            }}
                          >
                            {videoDurations[vid.id]
                              ? formatTime(videoDurations[vid.id])
                              : '0:00'}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography>Video bulunmamaktadır</Typography>
                    )}
                  </>
                )}
              </Card>
            </Stack>
          </Grid>
          {/* Sağ Panel: PDF veya Video Gösterici */}
          <Grid item xs={12} md={8}>
            {selectedTab === 'docs' ? (
              <Card sx={{ height: '600px', overflow: 'hidden' }}>
                {selectedDocument ? (
                  // <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  //   <Viewer
                  //     fileUrl={selectedDocument?.url}
                  //     plugins={[
                  //       defaultLayoutPluginInstance,
                  //       getFilePluginInstance,
                  //     ]}
                  //     defaultScale={1}
                  //   />
                  // </Worker>
                  <iframe
                    src={selectedDocument.url}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="Doküman"
                  />
                ) : (
                  <Typography align="center" variant="h6" sx={{ mt: 3 }}>
                    Doküman bulunmamaktadır
                  </Typography>
                )}
              </Card>
            ) : (
              <Card
                sx={{
                  height: '600px',
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                ref={containerRef}
                onMouseMove={handleMouseMove}
              >
                <Stack direction="row" justifyContent="space-between" mb={2}>
                  <Typography variant="h6" fontWeight="bold">
                    {selectedVideo?.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#888' }}>
                    {formatTime(duration)}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    flex: 1,
                    mb: 1,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  {selectedVideo && (
                    <>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 10,
                          left: 10,
                          padding: '4px 8px',
                          background: 'rgba(0,0,0,0.5)',
                          color: '#fff',
                          borderRadius: '4px',
                          zIndex: 10,
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {formatTime(currentTime)} / {formatTime(duration)}
                      </Box>
                      <ReactPlayer
                        key={selectedVideo.id}
                        ref={playerRef}
                        url={selectedVideo.url}
                        playing={playing}
                        width="100%"
                        height="100%"
                        controls={false}
                        onProgress={handleProgress}
                        onDuration={(dur) => {
                          setDuration(dur);
                          setVideoDurations((prev) => ({
                            ...prev,
                            [selectedVideo.id]: dur,
                          }));
                        }}
                      />
                      {showControls && (
                        <Box
                          onClick={handlePlayPause}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            cursor: 'pointer',
                            width: 80,
                            height: 80,
                            borderRadius: '50%',
                            background:
                              'radial-gradient(circle, rgba(255,255,255,1) 40%, rgba(255,255,255,0.2) 70%, transparent 100%)',
                            boxShadow: '0 0 0 3px rgba(255,255,255,0.1)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {playing ? (
                            <PauseIcon
                              sx={{ fontSize: 38, color: '#0666F6' }}
                            />
                          ) : (
                            <PlayArrowIcon
                              sx={{ fontSize: 38, color: '#0666F6' }}
                            />
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Slider
                    min={0}
                    max={100}
                    value={played}
                    onChange={(e, val) => {
                      setPlayed(val);
                      if (playerRef.current) {
                        playerRef.current.seekTo(val / 100);
                      }
                    }}
                    sx={{ flex: 1 }}
                  />
                  <IconButton onClick={handleToggleFullScreen}>
                    {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </Stack>
                <Typography variant="body1" sx={{ color: '#444', mt: 2 }}>
                  {selectedVideo?.description}
                </Typography>
              </Card>
            )}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
